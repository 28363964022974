import { t } from "i18next";

const SiteBreadcrumb = ({ title, parent }) => {
  return (
    <>
      <div className="breadcrumb-main">
        <div className="custom-container">
          <ul>
            <li>
              <a href="#">{t(parent)}</a>
            </li>
            <li>/</li>
            <li>
              <a href="#">{t(title)}</a>
            </li>
          </ul>
          <div className="breadcrumb-contain">
            <div>
              <h2>{t(title)}</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteBreadcrumb;
