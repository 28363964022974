import { t } from "i18next";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Card, CardHeader, Col, Collapse, Container, Row } from "reactstrap";
import {
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
} from "../../../helpers/CommonHelper";
import Config from "../../../helpers/Config";
import GlobalEnums from "../../../helpers/GlobalEnums";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";

const faqData = [
  {
    qus: "faq question 1",
    ans: "faq answer 1",
  },
  {
    qus: "faq question 2",
    ans: "faq answer 2",
  },
  {
    qus: "faq question 3",
    ans: "faq answer 3",
  },
  {
    qus: "faq question 4",
    ans: "faq answer 4",
  },
  {
    qus: "faq question 5",
    ans: "faq answer 5",
  },
];

const FaqPage = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [langCode, setLangCode] = useState("");
  const [id, setId] = useState(0);

  useEffect(() => {
    // declare the data fetching function
    const dataOperationFunc = async () => {
      let lnCode = getLanguageCodeFromSession();
      setLangCode(lnCode);

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["FaqPage"],
        null
      );
      if (
        arryRespLocalization !== null &&
        arryRespLocalization !== undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    // call the function
    dataOperationFunc().catch(console.error);
  }, []);

  return (
    <>
      <Helmet>
        <title>{siteTitle} - Frequently Asked Questions (FAQ)</title>
        <meta
          name="description"
          content={siteTitle + " - Frequently Asked Questions (FAQ)"}
        />
        <meta name="keywords" content="Frequently Asked Questions, FAQ"></meta>
      </Helmet>

      <SiteBreadcrumb title="faq" parent="home" />

      <section className="faq-section section-big-py-space ">
        <Container>
          <Row>
            <Col sm="12">
              <div className="accordion theme-accordion" id="accordionExample">
                {faqData.map((faq, i) => (
                  <Card key={i}>
                    <CardHeader id="headingOne">
                      <h5 className={`mb-0 ${id === i ? "show" : ""}`}>
                        <button
                          className="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          onClick={() => {
                            id === i ? setId(null) : setId(i);
                          }}
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          {t(faq.qus)}
                        </button>
                      </h5>
                    </CardHeader>
                    <Collapse
                      isOpen={id === i}
                      id="collapseOne"
                      className="collapse"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <p>{t(faq.ans)}</p>
                      </div>
                    </Collapse>
                  </Card>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default FaqPage;
