import { useEffect, useRef, useState } from "react";
import { Dropdown, DropdownToggle } from "reactstrap";
import {
  GetDefaultCurrencyCode,
  setCurrencyCodeInSession,
} from "../../../../helpers/CommonHelper";
import useGeoLocation from "react-ipgeolocation";

const currencyCodeArray = [
  {
    currencyCode: "USD",
    symbol: "$",
  },
  {
    currencyCode: "VND",
    symbol: "₫",
  },
];

const Currency = () => {
  const [openCurrency, setOpenCurrency] = useState(false);
  const location = useGeoLocation();
  let langCode = location.country;

  const toggleCurrency = () => {
    setOpenCurrency(!openCurrency);
  };

  const [currencyCode, setCurrencyCode] = useState("");
  const currency = useRef(GetDefaultCurrencyCode());
  const handleCurrencyCodeInSession = async (value) => {
    await setCurrencyCodeInSession(value);
    await setCurrencyCode(value);
    window.location.reload();
  };

  useEffect(() => {
    console.log(langCode);

    if (langCode === "VN") {
      setCurrencyCodeInSession("VND");
      setCurrencyCode("VND");
      localStorage.setItem("currencyCode", "VND");
    } else {
      setCurrencyCodeInSession(currency.current);
      setCurrencyCode(currency.current);
    }
  }, [langCode]);

  return (
    <div className="language-block">
      <div className="language-dropdown">
        <Dropdown isOpen={openCurrency} toggle={toggleCurrency}>
          <DropdownToggle
            tag="span"
            data-toggle="dropdown"
            aria-expanded={openCurrency}
            className="language-dropdown-click"
          >
            {
              currencyCodeArray?.find((x) => x.currencyCode === currencyCode)
                ?.symbol
            }{" "}
            {
              currencyCodeArray?.find((x) => x.currencyCode === currencyCode)
                ?.currencyCode
            }
            <i className="fa fa-angle-down" aria-hidden="true"></i>
          </DropdownToggle>
          <ul
            className={`language-dropdown-open ${openCurrency ? "" : "open"}`}
          >
            {currencyCodeArray.map((item, index) => (
              <li key={index}>
                <a
                  onClick={() => handleCurrencyCodeInSession(item.currencyCode)}
                  href="#"
                >
                  {`${item.symbol} ${item.currencyCode}`}
                </a>
              </li>
            ))}
          </ul>
        </Dropdown>
      </div>
    </div>
  );
};

export default Currency;
