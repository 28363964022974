import { t } from "i18next";
import { Col, Row } from "reactstrap";

const TopHeader = () => {
  return (
    <div className={`top-header`}>
      <div className="custom-container">
        <Row>
          <Col>
            <div className="top-header-left">
              <div className="shpping-order">
                <h6>{t("free shipping on order over $99")}</h6>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TopHeader;
