import { Media } from "reactstrap";
import signUpBanner from "./../../../resources/themeContent/images/banner-signup.jpg";
import signUpBannerMobile from "./../../../resources/themeContent/images/banner-signup-mobile.jpg";
import useMobileSize from "helpers/utils/isMobile";
import { useNavigate } from "react-router-dom";
import { getLanguageCodeFromSession, IsLogin } from "helpers/CommonHelper";
import { t } from "i18next";

const RegisterBanner = () => {
  const isMobile = useMobileSize(576);
  const navigate = useNavigate();
  if (IsLogin()) return <></>;

  if (isMobile)
    return (
      <section
        className="custom-container"
        style={{ paddingTop: "16px", paddingBottom: "16px" }}
      >
        <div style={{ position: "relative" }}>
          <Media
            src={signUpBannerMobile}
            className="img-fluid"
            alt="app-banner"
            style={{
              borderRadius: "12px",
              minHeight: "96px",
              objectFit: "cover",
            }}
          />
          <div
            className="d-flex flex-column align-items-center gap-2 "
            style={{ position: "absolute", top: "16px" }}
          >
            <p
              className="text-center"
              style={{
                color: "#0078a3",
                fontSize: "24px",
                fontWeight: "500",
                lineHeight: "32px",
                letterSpacing: "0.5px",
              }}
            >
              {t("signup now")}
            </p>
            <button
              className="btn btn-primary"
              style={{ padding: "8px 16px" }}
              onClick={() => {
                navigate("/" + getLanguageCodeFromSession() + "/signup");
              }}
            >
              {t("sign up")}
            </button>
          </div>
        </div>
      </section>
    );
  return (
    <section
      className="custom-container"
      style={{ paddingTop: "16px", paddingBottom: "16px" }}
    >
      <div style={{ position: "relative" }}>
        <Media
          src={signUpBanner}
          className="img-fluid"
          alt="app-banner"
          style={{ minHeight: "96px", objectFit: "cover" }}
        />
        <div
          className="d-flex flex-column align-items-center gap-3"
          style={{
            position: "absolute",
            top: "50%",
            right: "10%",
            transform: "translateY(-50%)",
          }}
        >
          <p
            className="text-center"
            style={{ color: "#0078a3", fontSize: "24px", fontWeight: "500" }}
          >
            {t("signup now")}
          </p>
          <button
            className="btn btn-normal"
            onClick={() => {
              navigate("/" + getLanguageCodeFromSession() + "/signup");
            }}
          >
            {t("sign up")}
          </button>
        </div>
      </div>
    </section>
  );
};

export default RegisterBanner;
