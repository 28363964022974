import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { Col, Row } from "reactstrap";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import { GetDefaultCurrencyCode } from "../../../helpers/CommonHelper";
import Config from "../../../helpers/Config";
import { LOADER_DURATION } from "../../../helpers/Constants";
import rootAction from "../../../stateManagment/actions/rootAction";
import Skeleton from "../products/Skeleton/Skeleton";
import ProductBox from "./ProductBox";

var settings = {
  arrows: true,
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 6,
  slidesToScroll: 1,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1700,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        infinite: true,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

// const productCollection = [
//   {
//     __typename: "Product",
//     id: 31,
//     title: "pink babysuit",
//     description:
//       "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.",
//     type: "kids",
//     brand: "babyhug",
//     category: "kids",
//     price: 75,
//     new: true,
//     sale: true,
//     discount: 40,
//     stock: 15,
//     variants: [
//       {
//         __typename: "VariantType",
//         id: "31.1",
//         sku: "sku31",
//         size: "m",
//         color: "pink",
//         image_id: 3111,
//       },
//       {
//         __typename: "VariantType",
//         id: "31.2",
//         sku: "skul31",
//         size: "m",
//         color: "red",
//         image_id: 3112,
//       },
//     ],
//     images: [
//       {
//         __typename: "ImageType",
//         image_id: 3111,
//         id: "31.1",
//         alt: "pink",
//         src: "kids/product/14.jpg",
//       },
//       {
//         __typename: "ImageType",
//         image_id: 3112,
//         id: "31.2",
//         alt: "red",
//         src: "kids/product/15.jpg",
//       },
//     ],
//   },
//   {
//     __typename: "Product",
//     id: 43,
//     title: "shoes 1",
//     description:
//       "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.",
//     type: "shoes",
//     brand: "decathlon",
//     category: "shoes",
//     price: 150,
//     new: true,
//     sale: true,
//     discount: 60,
//     stock: 4,
//     variants: [
//       {
//         __typename: "VariantType",
//         id: "43.1",
//         sku: "sku43",
//         size: "35",
//         color: "brown",
//         image_id: 4311,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.2",
//         sku: "skumg43",
//         size: "35",
//         color: "black",
//         image_id: 4312,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.3",
//         sku: "skums43",
//         size: "36",
//         color: "brown",
//         image_id: 4311,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.4",
//         sku: "skusp43",
//         size: "36",
//         color: "black",
//         image_id: 4312,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.5",
//         sku: "skusg43",
//         size: "37",
//         color: "brown",
//         image_id: 4311,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.6",
//         sku: "skusb43",
//         size: "37",
//         color: "black",
//         image_id: 4312,
//       },
//     ],
//     images: [
//       {
//         __typename: "ImageType",
//         image_id: 4311,
//         id: "43.1",
//         alt: "brown",
//         src: "pro/1.jpg",
//       },
//       {
//         __typename: "ImageType",
//         image_id: 4312,
//         id: "43.2",
//         alt: "black",
//         src: "pro/19.jpg",
//       },
//     ],
//   },
//   {
//     __typename: "Product",
//     id: 31,
//     title: "pink babysuit",
//     description:
//       "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.",
//     type: "kids",
//     brand: "babyhug",
//     category: "kids",
//     price: 75,
//     new: true,
//     sale: true,
//     discount: 40,
//     stock: 15,
//     variants: [
//       {
//         __typename: "VariantType",
//         id: "31.1",
//         sku: "sku31",
//         size: "m",
//         color: "pink",
//         image_id: 3111,
//       },
//       {
//         __typename: "VariantType",
//         id: "31.2",
//         sku: "skul31",
//         size: "m",
//         color: "red",
//         image_id: 3112,
//       },
//     ],
//     images: [
//       {
//         __typename: "ImageType",
//         image_id: 3111,
//         id: "31.1",
//         alt: "pink",
//         src: "kids/product/14.jpg",
//       },
//       {
//         __typename: "ImageType",
//         image_id: 3112,
//         id: "31.2",
//         alt: "red",
//         src: "kids/product/15.jpg",
//       },
//     ],
//   },
//   {
//     __typename: "Product",
//     id: 43,
//     title: "shoes 1",
//     description:
//       "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.",
//     type: "shoes",
//     brand: "decathlon",
//     category: "shoes",
//     price: 150,
//     new: true,
//     sale: false,
//     discount: 60,
//     stock: 4,
//     variants: [
//       {
//         __typename: "VariantType",
//         id: "43.1",
//         sku: "sku43",
//         size: "35",
//         color: "brown",
//         image_id: 4311,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.2",
//         sku: "skumg43",
//         size: "35",
//         color: "black",
//         image_id: 4312,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.3",
//         sku: "skums43",
//         size: "36",
//         color: "brown",
//         image_id: 4311,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.4",
//         sku: "skusp43",
//         size: "36",
//         color: "black",
//         image_id: 4312,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.5",
//         sku: "skusg43",
//         size: "37",
//         color: "brown",
//         image_id: 4311,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.6",
//         sku: "skusb43",
//         size: "37",
//         color: "black",
//         image_id: 4312,
//       },
//     ],
//     images: [
//       {
//         __typename: "ImageType",
//         image_id: 4311,
//         id: "43.1",
//         alt: "brown",
//         src: "pro/1.jpg",
//       },
//       {
//         __typename: "ImageType",
//         image_id: 4312,
//         id: "43.2",
//         alt: "black",
//         src: "pro/19.jpg",
//       },
//     ],
//   },
//   {
//     __typename: "Product",
//     id: 31,
//     title: "pink babysuit",
//     description:
//       "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.",
//     type: "kids",
//     brand: "babyhug",
//     category: "kids",
//     price: 75,
//     new: true,
//     sale: false,
//     discount: 40,
//     stock: 15,
//     variants: [
//       {
//         __typename: "VariantType",
//         id: "31.1",
//         sku: "sku31",
//         size: "m",
//         color: "pink",
//         image_id: 3111,
//       },
//       {
//         __typename: "VariantType",
//         id: "31.2",
//         sku: "skul31",
//         size: "m",
//         color: "red",
//         image_id: 3112,
//       },
//     ],
//     images: [
//       {
//         __typename: "ImageType",
//         image_id: 3111,
//         id: "31.1",
//         alt: "pink",
//         src: "kids/product/14.jpg",
//       },
//       {
//         __typename: "ImageType",
//         image_id: 3112,
//         id: "31.2",
//         alt: "red",
//         src: "kids/product/15.jpg",
//       },
//     ],
//   },
//   {
//     __typename: "Product",
//     id: 43,
//     title: "shoes 1",
//     description:
//       "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.",
//     type: "shoes",
//     brand: "decathlon",
//     category: "shoes",
//     price: 150,
//     new: true,
//     sale: false,
//     discount: 60,
//     stock: 4,
//     variants: [
//       {
//         __typename: "VariantType",
//         id: "43.1",
//         sku: "sku43",
//         size: "35",
//         color: "brown",
//         image_id: 4311,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.2",
//         sku: "skumg43",
//         size: "35",
//         color: "black",
//         image_id: 4312,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.3",
//         sku: "skums43",
//         size: "36",
//         color: "brown",
//         image_id: 4311,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.4",
//         sku: "skusp43",
//         size: "36",
//         color: "black",
//         image_id: 4312,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.5",
//         sku: "skusg43",
//         size: "37",
//         color: "brown",
//         image_id: 4311,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.6",
//         sku: "skusb43",
//         size: "37",
//         color: "black",
//         image_id: 4312,
//       },
//     ],
//     images: [
//       {
//         __typename: "ImageType",
//         image_id: 4311,
//         id: "43.1",
//         alt: "brown",
//         src: "pro/1.jpg",
//       },
//       {
//         __typename: "ImageType",
//         image_id: 4312,
//         id: "43.2",
//         alt: "black",
//         src: "pro/19.jpg",
//       },
//     ],
//   },
//   {
//     __typename: "Product",
//     id: 43,
//     title: "shoes 1",
//     description:
//       "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.",
//     type: "shoes",
//     brand: "decathlon",
//     category: "shoes",
//     price: 150,
//     new: true,
//     sale: false,
//     discount: 60,
//     stock: 4,
//     variants: [
//       {
//         __typename: "VariantType",
//         id: "43.1",
//         sku: "sku43",
//         size: "35",
//         color: "brown",
//         image_id: 4311,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.2",
//         sku: "skumg43",
//         size: "35",
//         color: "black",
//         image_id: 4312,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.3",
//         sku: "skums43",
//         size: "36",
//         color: "brown",
//         image_id: 4311,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.4",
//         sku: "skusp43",
//         size: "36",
//         color: "black",
//         image_id: 4312,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.5",
//         sku: "skusg43",
//         size: "37",
//         color: "brown",
//         image_id: 4311,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.6",
//         sku: "skusb43",
//         size: "37",
//         color: "black",
//         image_id: 4312,
//       },
//     ],
//     images: [
//       {
//         __typename: "ImageType",
//         image_id: 4311,
//         id: "43.1",
//         alt: "brown",
//         src: "pro/1.jpg",
//       },
//       {
//         __typename: "ImageType",
//         image_id: 4312,
//         id: "43.2",
//         alt: "black",
//         src: "pro/19.jpg",
//       },
//     ],
//   },
//   {
//     __typename: "Product",
//     id: 43,
//     title: "shoes 1",
//     description:
//       "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.",
//     type: "shoes",
//     brand: "decathlon",
//     category: "shoes",
//     price: 150,
//     new: true,
//     sale: true,
//     discount: 60,
//     stock: 4,
//     variants: [
//       {
//         __typename: "VariantType",
//         id: "43.1",
//         sku: "sku43",
//         size: "35",
//         color: "brown",
//         image_id: 4311,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.2",
//         sku: "skumg43",
//         size: "35",
//         color: "black",
//         image_id: 4312,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.3",
//         sku: "skums43",
//         size: "36",
//         color: "brown",
//         image_id: 4311,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.4",
//         sku: "skusp43",
//         size: "36",
//         color: "black",
//         image_id: 4312,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.5",
//         sku: "skusg43",
//         size: "37",
//         color: "brown",
//         image_id: 4311,
//       },
//       {
//         __typename: "VariantType",
//         id: "43.6",
//         sku: "skusb43",
//         size: "37",
//         color: "black",
//         image_id: 4312,
//       },
//     ],
//     images: [
//       {
//         __typename: "ImageType",
//         image_id: 4311,
//         id: "43.1",
//         alt: "brown",
//         src: "pro/1.jpg",
//       },
//       {
//         __typename: "ImageType",
//         image_id: 4312,
//         id: "43.2",
//         alt: "black",
//         src: "pro/19.jpg",
//       },
//     ],
//   },
// ];

/*************  ✨ Codeium Command ⭐  *************/
/**
 * PopularProducts component fetches and displays a list of popular products.
 * It uses a sliding carousel to present the products and supports hover effects
 * on product boxes. The component fetches the product data on mount and updates
 * the product list and pagination information accordingly. It also manages loading
 * states with a loader that shows while data is being fetched.
 *
 * Props:
 * - hoverEffect: A boolean controlling hover effect on product boxes.
 *
 * Uses:
 * - useDispatch: To dispatch Redux actions for loading states.
 * - useState: To manage local state for products list and pagination.
 * - useRef: To store currency code.
 * - useSelector: To access language code from Redux state.
 * - useEffect: To perform data fetching on component mount.
 *
 * Returns:
 * - JSX for rendering the popular products section.
 */

/******  2eee35a6-ca50-4465-b1bb-27bf939de3ee  *******/
const PopularProducts = ({ hoverEffect }) => {
  const dispatch = useDispatch();
  const [ProductsList, setProductsList] = useState([]);
  const [PaginationInfo, setPaginationInfo] = useState({
    PageNo: 1,
    PageSize: 20,
    TotalRecords: 0,
  });
  const currency = useRef(GetDefaultCurrencyCode());
  const langCode = useSelector((state) => state.commonReducer.langCodeInRedux);

  useEffect(() => {
    // declare the data fetching function
    const dataOperationInUserEffect = async () => {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const param = {
        requestParameters: {
          PageNo: PaginationInfo.PageNo,
          PageSize: PaginationInfo.PageSize,
          Currency: currency.current,
          LanguageId: langCode,
          recordValueJson: "[]",
        },
      };

      const response = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_POPULAR_PRODUCTS_LIST"],
        null,
        param,
        headers,
        "POST",
        true
      );
      if (response !== null && response.data !== null) {
        await setProductsList(JSON.parse(response.data.data));
      }
    };

    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    dataOperationInUserEffect().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, [
    PaginationInfo.PageNo,
    PaginationInfo.PageSize,
    dispatch,
    currency,
    langCode,
  ]);

  return (
    <>
      <div className="title1 section-mt-space">
        <h4>{t("popular products")}</h4>
      </div>
      <div className="product section-pb-space">
        <div className="custom-container">
          <Row>
            <Col className="pe-0">
              <div className="product-slide-6 ratio_asos no-arrow">
                <div>
                  {!ProductsList || ProductsList.length < 0 ? (
                    <Skeleton />
                  ) : (
                    <Slider {...settings}>
                      {ProductsList &&
                        ProductsList.map((itm, i) => (
                          <div key={i}>
                            <ProductBox
                              hoverEffect={hoverEffect}
                              item={itm}
                              layout=""
                              ProductDetailPageForceUpload={false}
                            />
                          </div>
                        ))}
                    </Slider>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default PopularProducts;
