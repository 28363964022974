//--Redux related imports starts here
import { Provider, useDispatch } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, reduxStore } from "../src/stateManagment/reduxStore";
//--Redux related imports ends here

// Styles
import {
  getLanguageCodeFromSession,
  setLanguageCodeInSession,
} from "helpers/CommonHelper";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useGeoLocation from "react-ipgeolocation";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Router from "routes/routes";
import GoTop from "web/components/layout/GoTop";
import "./locales/i18n.js";
import "./resources//custom/css/site.css";
import "./resources/themeContent/scss/app.scss";
import rootAction from "stateManagment/actions/rootAction";

function App() {
  const { i18n } = useTranslation();
  const location = useGeoLocation();

  const dispatch = reduxStore.dispatch;
  const langCodeInRedux = reduxStore.getState().commonReducer.langCodeInRedux;

  let langCode = location.country === "VN" ? "vi" : "en";

  useEffect(() => {
    async function getCurrentLocation() {
      let localLang = getLanguageCodeFromSession();
      if (langCode !== localLang) {
        dispatch(rootAction.commonAction.setLangCodeInRedux(langCode));
        await i18n.changeLanguage(langCodeInRedux);
        await setLanguageCodeInSession(langCodeInRedux);
        await localStorage.setItem(
          "currencyCode",
          langCode.toLowerCase() === "vi" ? "VND" : "USD"
        );
      }
    }
    getCurrentLocation();
  }, [i18n, langCode, dispatch, langCodeInRedux]);

  return (
    <Provider store={reduxStore}>
      <PersistGate loading={null} persistor={persistor}>
        <Router />
        <GoTop />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={true}
          pauseOnHover={true}
          closeOnClick={true}
          theme="colored"
        />
      </PersistGate>
    </Provider>
  );
}

export default App;
