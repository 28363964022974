import { MakeApiCallAsync } from "helpers/ApiHelpers";
import Config from "helpers/Config";
import { LOADER_DURATION } from "helpers/Constants";
import { showErrorMsg, showSuccessMsg } from "helpers/ValidationHelper";
import { t } from "i18next";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Media, Spinner } from "reactstrap";
import rootAction from "stateManagment/actions/rootAction";

const BrainTreeUrl = () => {
  var dropin = require("braintree-web-drop-in");
  const [token, setToken] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [loadingPayment, setLoadingPayment] = React.useState(true);

  const dispatch = useDispatch();

  const loginUserDataJson = useSelector((state) => state.userReducer.user);
  const loginUser = JSON.parse(loginUserDataJson ?? "{}");

  const cartJsonDataSession = useSelector(
    (state) => state.cartReducer.cartItems
  );
  const cartItemsSession = JSON.parse(cartJsonDataSession ?? "[]");

  const stringify = localStorage.getItem("v1shop_createOrder");
  const createOrder = stringify ? JSON.parse(stringify) : undefined;

  const currentOder = useMemo(() => {
    let data = localStorage.getItem("v1shop_createOrder");
    return data ? JSON.parse(data) : undefined;
  }, []);

  const onPurchase = useCallback(() => {
    if (!currentOder?.paymentTransactionId) {
      toast.error(t("please payment first!"));
    }
  }, [currentOder]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://js.braintreegateway.com/web/dropin/1.42.0/js/dropin.min.js";
    script.async = true;
    document.head.appendChild(script);
  }, []);

  const braintreeGenerateToken = async () => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const apiResponse = await MakeApiCallAsync(
      Config.END_POINT_NAMES["BRAINTREE_GENERATE_TOKEN"],
      "", //subUrl API
      "", //  params
      headers,
      "GET",
      true
    );

    return apiResponse;
  };

  const braintreeCreatePayment = async ({ amount, nonce, currency }) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const apiResponse = await MakeApiCallAsync(
      Config.END_POINT_NAMES["BRAINTREE_CREATE"],
      "", //subUrl API
      { amount, nonce, currency }, //  params
      headers,
      "POST",
      true
    );

    return apiResponse;
  };

  const handleCreateOrder = async (paymentTransactionId) => {
    try {
      setLoading(true);
      const headersStrip = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const paramSrip = {
        requestParameters: {
          UserID: loginUser.UserID,
          OrderNote: createOrder.orderNote || "",
          cartJsonData: JSON.stringify(cartItemsSession),
          CouponCode: createOrder.couponCode || "",
          PaymentMethod: createOrder.paymentMethodId,
          paymentToken: "",
          payPalOrderConfirmJson: "",
          recordValueJson: "[]",
          applePayData: paymentTransactionId,
        },
      };

      const stripServerResponse = await MakeApiCallAsync(
        Config.END_POINT_NAMES["POST_CUSTOMER_ORDER"],
        Config["COMMON_CONTROLLER_SUB_URL"],
        paramSrip,
        headersStrip,
        "POST",
        true
      );
      if (
        stripServerResponse !== null &&
        stripServerResponse.data !== null &&
        stripServerResponse.status === 200
      ) {
        let stripServerResponseDetail = JSON.parse(
          stripServerResponse.data.data !== undefined &&
            stripServerResponse.data.data !== ""
            ? stripServerResponse.data.data
            : "[]"
        );

        if (
          stripServerResponseDetail.length > 0 &&
          stripServerResponseDetail[0].ResponseMsg !== undefined &&
          stripServerResponseDetail[0].ResponseMsg ===
            "Order Placed Successfully"
        ) {
          showSuccessMsg("Order Placed Successfully!");

          setTimeout(function () {
            //--clear customer cart
            dispatch(rootAction.cartAction.setCustomerCart("[]"));
            dispatch(rootAction.cartAction.SetTotalCartItems(0));
            localStorage.setItem("cartItems", "[]");
          }, 1000);

          window.opener.isPaymentSuccess = true;
          setLoading(false);
          window.close();
        } else {
          showErrorMsg("An error occurred. Please try again!");
          setLoading(false);
        }
      } else {
        showErrorMsg("An error occurred. Please try again!");
        setLoading(false);
      }
    } catch (err) {
      showErrorMsg("An error occurred. Please try again!");
      console.log(err.message);

      setLoading(false);
      //--stop loader
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);
    }
  };

  useEffect(() => {
    const createToken = async () => {
      const res = await braintreeGenerateToken();
      setToken(res.data.data);
      setLoadingPayment(false);
    };

    createToken();
  }, []);

  useEffect(() => {
    const button = document.getElementById("submit-button");

    dropin.create(
      {
        authorization: token || "",
        container: "#dropin-container",
        locale: "en_US",
        // paymentOptionPriority: ["applePay"],
        paymentOptionPriority: ["applePay", "card"],
        applePay: {
          displayName: "V1Hub Shop",
          paymentRequest: {
            total: {
              label: "Total",
              amount: currentOder.total,
            },
            // We recommend collecting billing address information, at minimum
            // billing postal code, and passing that billing postal code with all
            // Apple Pay transactions as a best practice.
            requiredBillingContactFields: ["postalAddress"],
          },
        },
        // googlePay: {
        //   googlePayVersion: 2,
        //   merchantId: 'merchant-id-from-google',
        //   transactionInfo: {
        //     totalPriceStatus: 'FINAL',
        //     totalPrice: currentOder?.totalPrice,
        //     currencyCode: 'USD',
        //   },
        //   allowedPaymentMethods: [
        //     {
        //       type: 'CARD',
        //       parameters: {
        //         // We recommend collecting and passing billing address information with all Google Pay transactions as a best practice.
        //         billingAddressRequired: true,
        //         billingAddressParameters: {
        //           format: 'FULL',
        //         },
        //       },
        //     },
        //   ],
        // },
      },
      function (err, instance) {
        button?.addEventListener("click", function () {
          instance?.requestPaymentMethod(async function (err, payload) {
            const res = await braintreeCreatePayment({
              amount: currentOder.total,
              nonce: payload.nonce,
              currency: currentOder.Currency,
            });
            const paymentTransactionId = res.data.data;
            // console.log(res.data);

            handleCreateOrder({ paymentTransactionId });
          });
        });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropin, token, currentOder]);

  useEffect(() => {
    let containerInterval = setInterval(() => {
      let container = document.getElementById("dropin-container");
      if (container?.innerHTML === "") {
        setLoadingPayment(false);
      } else {
        setLoadingPayment(false);
        return null;
      }
    }, 2000);
    if (!loadingPayment) {
      return clearInterval(containerInterval);
    }
  }, [loadingPayment]);

  if (loading)
    return (
      <div className="d-flex justify-content-center mt-3">
        <Spinner className="me-2" animation="border" variant="primary" />
        <h3>Processing for create order</h3>
      </div>
    );

  return (
    <div className="braintree-module mt-5">
      <div className="checkout container">
        {loadingPayment && (
          <div className="d-flex justify-content-center mt-5">
            <Spinner className="me-2" animation="border" variant="primary" />
            <h3>Processing for create payment</h3>
          </div>
        )}
        {!loadingPayment && (
          <header className="d-flex align-items-center">
            <h6>Make a payment with </h6>
            <a
              className="mb-1"
              href="https://www.braintreepayments.com"
              target="_blank"
              rel="noreferrer"
            >
              <Media
                src={"/braintree.svg"}
                className="img-fluid  "
                alt="logo"
              />
            </a>
          </header>
        )}
        <div id="dropin-container"></div>
        {!loadingPayment && (
          <div className="d-flex justify-content-end">
            <button
              id="submit-button"
              onClick={onPurchase}
              className="button button--small button--green"
            >
              Purchase
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(BrainTreeUrl);
