import { t } from "i18next";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import Config from "../../../helpers/Config";
import { LOADER_DURATION } from "../../../helpers/Constants";
import {
  showErrorMsg,
  showSuccessMsg,
  validateAnyFormField,
} from "../../../helpers/ValidationHelper";
import rootAction from "../../../stateManagment/actions/rootAction";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
// import BestFacilities from "../../components/shared/BestFacilities";

const ContactUs = () => {
  const dispatch = useDispatch();
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);
  const [FullName, setFullName] = useState("");
  const [Email, setEmail] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [Subject, setSubject] = useState("");
  const [Message, setMessage] = useState("");

  const handleContactUsForm = async (event) => {
    event.preventDefault();

    try {
      //--start loader
      dispatch(rootAction.commonAction.setLoading(true));

      let isValid = false;
      let validationArray = [];

      isValid = validateAnyFormField("Name", FullName, "text", null, 200, true);
      if (isValid == false) {
        validationArray.push({
          isValid: isValid,
        });
      }

      isValid = validateAnyFormField("Email", Email, "email", null, 100, true);
      if (isValid == false) {
        validationArray.push({
          isValid: isValid,
        });
      }

      // isValid = validateAnyFormField('Phone Number', PhoneNumber, 'text', null, 20, true);
      // if (isValid == false) {
      //   validationArray.push({
      //     isValid: isValid
      //   });
      // }

      isValid = validateAnyFormField(
        "Subject",
        Subject,
        "text",
        null,
        150,
        true
      );
      if (isValid == false) {
        validationArray.push({
          isValid: isValid,
        });
      }

      isValid = validateAnyFormField(
        "Message",
        Message,
        "text",
        null,
        2000,
        true
      );
      if (isValid == false) {
        validationArray.push({
          isValid: isValid,
        });
      }

      //--check if any field is not valid
      if (validationArray !== null && validationArray.length > 0) {
        isValid = false;
        return false;
      } else {
        isValid = true;
      }

      if (isValid) {
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };

        const param = {
          requestParameters: {
            FullName: FullName,
            Email: Email,
            PhoneNumber: PhoneNumber,
            Subject: Subject,
            Message: Message,
          },
        };

        //--make api call for data operation
        const response = await MakeApiCallAsync(
          Config.END_POINT_NAMES["CONTACT_US"],
          null,
          param,
          headers,
          "POST",
          true
        );
        if (response !== null && response.data !== null) {
          let userData = JSON.parse(response.data.data);
          if (
            userData.length > 0 &&
            userData[0].ResponseMsg !== undefined &&
            userData[0].ResponseMsg == "Saved Successfully"
          ) {
            showSuccessMsg("Message sent successfully!");

            //--Empty form fields
            setFullName("");
            setEmail("");
            setPhoneNumber("");
            setSubject("");
            setMessage("");
          } else {
            showErrorMsg("An error occurred. Please try again!");
            return false;
          }
        }
      }
    } catch (err) {
      console.log(err);
      showErrorMsg("An error occurred. Please try again!");

      return false;
    } finally {
      //--stop loader
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);
    }
  };

  return (
    <>
      <Helmet>
        <title>{siteTitle} - Contact Us</title>
        <meta name="description" content={siteTitle + " - Contact Us"} />
        <meta name="keywords" content="Contact Us"></meta>
      </Helmet>

      <SiteBreadcrumb title="Contact Us" parent="Home" />

      <section className="contact-page">
        <div className="custom-container">
          <h4 className="text-center mb-3">{t("get in touch")}</h4>
          <Row className="justify-content-center">
            <Col xl="7">
              <Form className="theme-form" onSubmit={handleContactUsForm}>
                <div className="form-row row">
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="FullName" id="lbl_cont_form_name">
                        {t("name")}
                        <span className="required-field">*</span>
                      </Label>
                      <Input
                        type="text"
                        name="FullName"
                        id="FullName"
                        className="form-control"
                        required={true}
                        data-error="Please enter your name"
                        placeholder={t("enter your name")}
                        value={FullName}
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="Email" id="lbl_cont_form_email">
                        {"Email"}
                        <span className="required-field">*</span>
                      </Label>
                      <Input
                        type="email"
                        name="Email"
                        id="Email"
                        className="form-control"
                        required={true}
                        data-error="Please enter your email"
                        placeholder={t("enter your email")}
                        value={Email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="PhoneNumber" id="lbl_cont_form_phon">
                        {t("phone number")}
                      </Label>
                      <Input
                        type="text"
                        name="PhoneNumber"
                        id="PhoneNumber"
                        className="form-control"
                        required={false}
                        data-error="Please enter your phone number"
                        placeholder={t("enter your phone number")}
                        value={PhoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="Subject" id="lbl_cont_form_subj">
                        {t("subject")}
                        <span className="required-field">*</span>
                      </Label>
                      <Input
                        type="text"
                        name="Subject"
                        id="Subject"
                        className="form-control"
                        required={true}
                        data-error="Please enter subject"
                        placeholder={t("enter subject here")}
                        value={Subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <div>
                      <Label htmlFor="message" id="lbl_cont_form_msg">
                        {t("your message")}
                        <span className="required-field">*</span>
                      </Label>
                      <textarea
                        name="Message"
                        id="message"
                        cols="30"
                        rows="8"
                        required={true}
                        data-error="Please enter your message"
                        className="form-control"
                        placeholder={t("enter your message")}
                        value={Message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col md="12">
                    <button className="btn btn-normal" type="submit">
                      {t("send your message")}
                    </button>
                  </Col>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
