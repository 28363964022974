import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
} from "../../../../helpers/CommonHelper";
import { LOADER_DURATION } from "../../../../helpers/Constants";
import GlobalEnums from "../../../../helpers/GlobalEnums";
import rootAction from "../../../../stateManagment/actions/rootAction";

const MenuBar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [langCode, setLangCode] = useState("");

  const loginUserDataJson = useSelector((state) => state.userReducer.user);
  const loginUser = JSON.parse(loginUserDataJson ?? "{}");
  const path = window.location.pathname;

  useEffect(() => {
    const getDataInUseEffect = async () => {
      let lnCode = getLanguageCodeFromSession();
      await setLangCode(lnCode);
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["MegaMenu"],
        null
      );
      if (
        arryRespLocalization !== null &&
        arryRespLocalization !== undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };

    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    getDataInUseEffect().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, []);

  return (
    <>
      {path !== "/Layouts/layout3" && (
        <li>
          <div
            className="mobile-back text-left"
            onClick={() => {
              props.setMenuResponsive(false);
              document.body.style.overflow = "visible";
            }}
          >
            <img src="/images/icon-back.png" alt="back" />
            {/* <i className="fa fa-angle-left " aria-hidden="true"></i> */}
          </div>
        </li>
      )}
      <li>
        <Link
          to={`/${getLanguageCodeFromSession()}/`}
          className={`dark-menu-item has-submenu ${
            path === "/" ||
            path === "/#" ||
            path === "/" + getLanguageCodeFromSession() + "/"
              ? "active"
              : ""
          }`}
          id="lbl_mgmenu_home"
          onClick={() => {
            props.setMenuResponsive(false);
            document.body.style.overflow = "visible";
          }}
        >
          {t("home")}
        </Link>
      </li>
      <li>
        <Link
          to={`/${getLanguageCodeFromSession()}/all-products/0/all-categories`}
          className={`dark-menu-item has-submenu ${
            path.includes("all-products") ? "active" : ""
          } `}
          id="lbl_mgmenu_products"
          onClick={() => {
            props.setMenuResponsive(false);
            document.body.style.overflow = "visible";
          }}
        >
          {t("All Products")}
        </Link>
      </li>
      <li>
        <Link
          to={`/${getLanguageCodeFromSession()}/contact-us`}
          id="lbl_thead_contct"
          className={`dark-menu-item has-submenu ${
            path.includes("contact-us") ? "active" : ""
          }`}
          onClick={() => {
            props.setMenuResponsive(false);
            document.body.style.overflow = "visible";
          }}
        >
          {t("Contact")}
        </Link>
      </li>
    </>
  );
};

export default MenuBar;
