import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Col, Container, Media, Row } from "reactstrap";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import {
  GetDefaultCurrencyCode,
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
} from "../../../helpers/CommonHelper";
import Config from "../../../helpers/Config";
import { LOADER_DURATION } from "../../../helpers/Constants";
import GlobalEnums from "../../../helpers/GlobalEnums";
import { checkIfStringIsEmtpy } from "../../../helpers/ValidationHelper";
import logoImage from "../../../resources/custom/images/v1hub_shop_logo.jpg";
import rootAction from "../../../stateManagment/actions/rootAction";
import SubscribeNewsLetter from "../shared/SubscribeNewsLetter";

const Footer = ({ layoutLogo }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [paymentMethods, setPaymentMethods] = useState([]);
  // const [adminPanelBaseURL, setadminPanelBaseURL] = useState(
  //   Config["ADMIN_BASE_URL"]
  // );
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [LogoImageFromStorage, setLogoImageFromStorage] = useState(
    useSelector((state) => state.commonReducer.websiteLogoInLocalStorage)
  );
  const currency = useRef(GetDefaultCurrencyCode());

  useEffect(() => {
    // declare the data fetching function
    const DataOperationFunc = async () => {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const param = {
        requestParameters: {
          recordValueJson: "[]",
          Currency: currency.current,
        },
      };

      //--Get payment methods
      const responsePaymentMethods = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_PAYMENT_METHODS"],
        null,
        param,
        headers,
        "POST",
        true
      );
      if (
        responsePaymentMethods !== null &&
        responsePaymentMethods.data !== null
      ) {
        await setPaymentMethods(JSON.parse(responsePaymentMethods.data.data));
        // console.log(JSON.parse(responsePaymentMethods.data.data));
      }

      //--Get Website Logo
      if (!checkIfStringIsEmtpy(LogoImageFromStorage)) {
        let paramLogo = {
          requestParameters: {
            recordValueJson: "[]",
          },
        };

        let WebsiteLogoInLocalStorage = "";
        let logoResponse = await MakeApiCallAsync(
          Config.END_POINT_NAMES["GET_WEBSITE_LOGO"],
          null,
          paramLogo,
          headers,
          "POST",
          true
        );
        if (logoResponse !== null && logoResponse.data !== null) {
          if (logoResponse.data.data !== "") {
            let logoData = JSON.parse(logoResponse.data.data);

            WebsiteLogoInLocalStorage = logoData[0].AppConfigValue;
            dispatch(
              rootAction.commonAction.setWebsiteLogo(WebsiteLogoInLocalStorage)
            );
            setLogoImageFromStorage(WebsiteLogoInLocalStorage);
          }
        }
      }
    };

    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    DataOperationFunc().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, [currency, LogoImageFromStorage, dispatch]);

  // useEffect(() => {
  //   // declare the data fetching function
  //   const dataOperationFunc = async () => {
  //     //-- Get website localization data
  //     let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
  //       GlobalEnums.Entities["Footer"],
  //       null
  //     );
  //     if (
  //       arryRespLocalization !== null &&
  //       arryRespLocalization !== undefined &&
  //       arryRespLocalization.length > 0
  //     ) {
  //       await setLocalizationLabelsArray(arryRespLocalization);
  //     }
  //   };
  //   // call the function
  //   dataOperationFunc().catch(console.error);
  // }, []);

  return (
    <footer className="footer-2">
      <Container>
        <Row className="row">
          <Col xs="12">
            <div className="footer-main-contian">
              <Row>
                <Col lg="4" md="12">
                  <div className="footer-left">
                    <div className="footer-logo">
                      <Media
                        src={logoImage}
                        className="img-fluid  "
                        alt="logo"
                      />
                    </div>
                    <div className="footer-detail">
                      <p>{t("footer text")}</p>
                      <ul className="paymant-bottom">
                        {paymentMethods?.map((item, idx) => (
                          <li key={idx}>
                            <a href="#">
                              <Media
                                src={item.ImageUrl}
                                className="img-fluid"
                                alt="pay"
                              />
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col lg="8" md="12">
                  <div className="footer-right">
                    <Row className="row">
                      <Col md="12">
                        <SubscribeNewsLetter />
                      </Col>
                      <Col md="12">
                        <div className="account-right">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="footer-box">
                                <div className="footer-title">
                                  <h5>{t("my account")}</h5>
                                </div>
                                <div className="footer-contant">
                                  <ul>
                                    <li>
                                      <Link
                                        to={`/${getLanguageCodeFromSession()}/login`}
                                        state={{ from: location }}
                                        onClick={() => window.scrollTo(0, 0)}
                                      >
                                        {t("login")}
                                      </Link>
                                    </li>

                                    <li>
                                      <Link
                                        to={`/${getLanguageCodeFromSession()}/cart`}
                                        onClick={() => window.scrollTo(0, 0)}
                                      >
                                        {t("cart")}
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to={`/${getLanguageCodeFromSession()}/all-products/0/all-categories`}
                                        onClick={() => window.scrollTo(0, 0)}
                                      >
                                        {t("all products")}
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to={`/${getLanguageCodeFromSession()}/about`}
                                        id="lbl_footr_about"
                                        onClick={() => window.scrollTo(0, 0)}
                                      >
                                        {t("about us")}
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to={`/${getLanguageCodeFromSession()}/contact-us`}
                                        id="lbl_footr_cont"
                                        onClick={() => window.scrollTo(0, 0)}
                                      >
                                        {t("contact us")}
                                      </Link>
                                    </li>

                                    <li>
                                      <Link
                                        to={`/${getLanguageCodeFromSession()}/signup`}
                                        onClick={() => window.scrollTo(0, 0)}
                                      >
                                        {t("create account")}
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="footer-box">
                                <div className="footer-title">
                                  <h5>{t("quick links")}</h5>
                                </div>
                                <div className="footer-contant">
                                  <ul>
                                    <li>
                                      <Link
                                        to={`/${getLanguageCodeFromSession()}/faq`}
                                        id="lbl_footr_faq"
                                        onClick={() => window.scrollTo(0, 0)}
                                      >
                                        {t("faq")}
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to={`/${getLanguageCodeFromSession()}/privacy-policy`}
                                        id="lbl_footr_privacy"
                                        onClick={() => window.scrollTo(0, 0)}
                                      >
                                        {t("privacy policy")}
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to={`/${getLanguageCodeFromSession()}/terms-and-conditions`}
                                        id="lbl_footr_terms"
                                        onClick={() => window.scrollTo(0, 0)}
                                      >
                                        {t("terms and conditions")}
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to={`/${getLanguageCodeFromSession()}/bulk-production`}
                                        id="lbl_footr_bulk"
                                        onClick={() => window.scrollTo(0, 0)}
                                      >
                                        {t("bulk production")}
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to={`/${getLanguageCodeFromSession()}/order-process`}
                                        id="lbl_footr_order"
                                        onClick={() => window.scrollTo(0, 0)}
                                      >
                                        {t("order process")}
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to={`/${getLanguageCodeFromSession()}/refund-policy`}
                                        id="lbl_footr_refund"
                                        onClick={() => window.scrollTo(0, 0)}
                                      >
                                        {t("refunds and returns")}
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to={`/${getLanguageCodeFromSession()}/shipping-information`}
                                        id="lbl_footr_shipping"
                                        onClick={() => window.scrollTo(0, 0)}
                                      >
                                        {t("shipping information")}
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="footer-box footer-contact-box">
                                <div className="footer-title">
                                  <h5>{t("contact us")}</h5>
                                </div>
                                <div className="footer-contant">
                                  <ul className="contact-list">
                                    <li>
                                      <i className="fa fa-map-marker"></i>
                                      <span>
                                        V1HubShop <br />
                                      </span>
                                    </li>

                                    <li>
                                      <i className="fa fa-envelope-o"></i>
                                      <span>
                                        <a
                                          href="mailto:support@v1hub.com"
                                          style={{ textTransform: "none" }}
                                        >
                                          support@v1hub.com
                                        </a>
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="app-link-block  bg-transparent">
        <Container>
          <Row>
            <div className="app-link-bloc-contain app-link-bloc-contain-1">
              <div className="app-item-group ">
                <div className="social-block">
                  <h6>{t("follow us")}</h6>
                  <ul className="social">
                    <li>
                      <Link to="https://www.facebook.com/v1hub" target="_blank">
                        <i className="fa fa-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.youtube.com/@V1Hub" target="_blank">
                        <i className="fa fa-youtube-play"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.instagram.com/v1hubshop/"
                        target="_blank"
                      >
                        <i className="fa fa-instagram"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.tiktok.com/@v1hub.shop"
                        target="_blank"
                      >
                        <i class="fab fa-tiktok"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
