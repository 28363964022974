import { Helmet } from "react-helmet";
import { Col, Row } from "reactstrap";
import Config from "../../../helpers/Config";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import { useState } from "react";
import { t } from "i18next";
const contents = [
  {
    content: ["terms and conditions subtitle"],
  },
  {
    title: "terms and conditions content 1",
    list: [
      "terms and conditions content 1.1",
      "terms and conditions content 1.2",
      "terms and conditions content 1.3",
    ],
  },
  {
    title: "terms and conditions content 2",
    list: [
      "terms and conditions content 2.1",
      "terms and conditions content 2.2",
      "terms and conditions content 2.3",
    ],
  },
  {
    title: "terms and conditions content 3",
    list: [
      "terms and conditions content 3.1",
      "terms and conditions content 3.2",
      "terms and conditions content 3.3",
    ],
  },
  {
    title: "terms and conditions content 4",
    list: [
      "terms and conditions content 4.1",
      "terms and conditions content 4.2",
    ],
  },
  {
    title: "terms and conditions content 5",
    list: [
      "terms and conditions content 5.1",
      "terms and conditions content 5.2",
    ],
  },
  {
    title: "terms and conditions content 6",
    list: [
      "terms and conditions content 6.1",
      "terms and conditions content 6.2",
    ],
  },
  {
    title: "terms and conditions content 7",
    list: [
      "terms and conditions content 7.1",
      "terms and conditions content 7.2",
    ],
  },
  {
    title: "terms and conditions content 8",
    list: [
      "terms and conditions content 8.1",
      "terms and conditions content 8.2",
      "terms and conditions content 8.3",
      "terms and conditions content 8.4",
    ],
  },
  {
    title: "terms and conditions content 9",
    list: ["terms and conditions content 9.1"],
  },
  {
    title: "terms and conditions content 10",
    list: ["terms and conditions content 10.1"],
  },
  {
    title: "terms and conditions content 11",
    list: ["terms and conditions content 11.1"],
  },
  {
    title: "terms and conditions content 12",
    list: ["terms and conditions content 12.1"],
  },
  {
    title: "terms and conditions content 13",
    list: ["terms and conditions content 13.1"],
  },
  {
    title: "terms and conditions content 14",
    list: ["terms and conditions content 14.1"],
  },
  {
    content: ["terms and conditions end content"],
  },
];

const TermCondition = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);
  return (
    <>
      <Helmet>
        <title>{siteTitle} - Terms and Conditions</title>
        <meta
          name="description"
          content={siteTitle + " - Terms and Conditions"}
        />
        <meta name="keywords" content="Terms and Conditions"></meta>
      </Helmet>

      <SiteBreadcrumb title="terms and conditions" parent="Home" />

      <section className="about-page mt-2 mt-md-4">
        <div className="custom-container" style={{ maxWidth: "900px" }}>
          <Row>
            {contents.map((content, index) => (
              <Col xs="12" className="mb-2 mt-1">
                {content.title && <h4 className="mb-2">{t(content.title)}</h4>}
                {content.list && content.list.length > 0 ? (
                  <ul style={{ listStyleType: "disc", paddingLeft: "32px" }}>
                    {content.list.map((item, index) => (
                      <li
                        key={index}
                        className="mb-2 pb-1"
                        // style={{ display: "list-item" }}
                      >
                        <p>{t(item)}</p>
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
                {content.content &&
                  content.content.length > 0 &&
                  content.content.map((item, index) => (
                    <p className="mb-2" key={index}>
                      {t(item)}
                    </p>
                  ))}
              </Col>
            ))}
          </Row>
        </div>
      </section>
    </>
  );
};

export default TermCondition;
