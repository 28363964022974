import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Dropdown, DropdownToggle } from "reactstrap";
import commonAction from "stateManagment/actions/commonAction";
import { setLanguageCodeInSession } from "../../../../helpers/CommonHelper";
import { reduxStore } from "stateManagment/reduxStore";

const langCodeArray = [
  {
    langCode: "en",
    name: "English",
  },
  {
    langCode: "vi",
    name: "Vietnamese",
  },
];

const Languages = () => {
  const [openLang, setOpenLang] = useState(false);
  const navigate = useNavigate();
  const dispatch = reduxStore.dispatch;
  const { i18n } = useTranslation();
  let langCode = useSelector((state) => state.commonReducer.langCodeInRedux);
  const [currentLang, setCurrentLang] = useState("");
  const pathname = useParams();
  const location = useLocation();

  const toggleLang = () => {
    setOpenLang(!openLang);
  };

  const handleLangCodeInSession = (value) => {
    dispatch(commonAction.setLangCodeInRedux(value));
    setCurrentLang(value);
    setLanguageCodeInSession(value);
    i18n.changeLanguage(value);
    console.log(pathname.langCode, value);

    let navigateURL = location.pathname.replace(
      `/${pathname.langCode}/`,
      `/${value}/`
    );
    navigate(navigateURL);
  };

  useEffect(() => {
    if (langCode) setCurrentLang(langCode);
  }, [langCode]);

  return (
    <div className="language-block">
      <div className="language-dropdown">
        <Dropdown isOpen={openLang} toggle={toggleLang}>
          <DropdownToggle
            tag="span"
            data-toggle="dropdown"
            aria-expanded={openLang}
            className="language-dropdown-click"
            style={{ textTransform: "uppercase" }}
          >
            {langCodeArray?.find((x) => x.langCode === currentLang)?.langCode}
            <i className="fa fa-angle-down" aria-hidden="true"></i>
          </DropdownToggle>
          <ul className={`language-dropdown-open ${openLang ? "" : "open"}`}>
            {langCodeArray.map((data, i) => (
              <li
                key={i}
                style={{ cursor: "pointer" }}
                onClick={() => handleLangCodeInSession(data.langCode)}
              >
                {data.name}
              </li>
            ))}
          </ul>
        </Dropdown>
      </div>
    </div>
  );
};

export default memo(Languages);
