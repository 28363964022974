import { Col, Row } from "reactstrap";
import filterIcon2 from "../../../resources/themeContent/images/category/icon/2.png";
import filterIcon3 from "../../../resources/themeContent/images/category/icon/3.png";
import filterIcon4 from "../../../resources/themeContent/images/category/icon/4.png";
import useMobileSize from "helpers/utils/isMobile";
import { t } from "i18next";

const ProductsFilterOptions = (props) => {
  const isMobile = useMobileSize(576);

  return (
    <>
      <Row>
        <Col xs="2" md="12">
          <div className="filter-main-btn">
            <div
              style={{ cursor: "pointer", position: "relative" }}
              onClick={(e) => {
                props.setLeftSidebarOpenCloseFromFilter(e, true);
              }}
            >
              <img
                src={"/images/icon-filter.png"}
                alt=""
                style={{ width: "20px" }}
              />
              {props.isFilterApplied && <span className="filter-badge" />}
            </div>
          </div>
        </Col>
        <Col xs="10" md="12">
          <div className="product-filter-content">
            <div className="collection-view">
              <ul>
                <li
                  onClick={() => {
                    props.setLayout("");
                    props.setGrid(props.cols);
                  }}
                >
                  <i className="fa fa-th grid-layout-view"></i>
                </li>
                <li
                  onClick={() => {
                    props.setLayout("list-view");
                    props.setGrid("col-lg-12");
                  }}
                >
                  <i className="fa fa-list-ul list-layout-view"></i>
                </li>
              </ul>
            </div>
            <div
              className="collection-grid-view"
              style={
                props.layout === "list-view" ? { opacity: 0 } : { opacity: 1 }
              }
            >
              <ul>
                <li onClick={() => props.setGrid("col-lg-6")}>
                  <img
                    src={filterIcon2}
                    alt=""
                    className="product-2-layout-view"
                  />
                </li>
                <li onClick={() => props.setGrid("col-lg-4")}>
                  <img
                    src={filterIcon3}
                    alt=""
                    className="product-3-layout-view"
                  />
                </li>
                <li onClick={() => props.setGrid("col-lg-3")}>
                  <img
                    src={filterIcon4}
                    alt=""
                    className="product-4-layout-view"
                  />
                </li>
              </ul>
            </div>
            <div className="product-page-per-view">
              <select onChange={(e) => props.setPageSizeFromProductFilter(e)}>
                <option value="12">
                  12 {isMobile ? t("products/page") : t("products per page")}
                </option>
                <option value="24">
                  24 {isMobile ? t("products/page") : t("products per page")}
                </option>
                <option value="48">
                  48 {isMobile ? t("products/page") : t("products per page")}
                </option>
                <option value="72">
                  72 {isMobile ? t("products/page") : t("products per page")}
                </option>
              </select>
            </div>
            <div className="product-page-filter">
              <select onChange={(e) => props.setSortByFilter(e)}>
                <option value="">{t("featured")}</option>

                <option value="Price ASC">{t("price ascending")}</option>
                <option value="Price DESC">{t("price descending")}</option>
                <option value="Date DESC">{t("date ascending")}</option>
                <option value="Date ASC">{t("date descending")}</option>
              </select>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ProductsFilterOptions;
