import { useState } from "react";
import { Helmet } from "react-helmet";
import { Col, Row } from "reactstrap";
import Config from "../../../helpers/Config";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import useMobileSize from "helpers/utils/isMobile";
import { t } from "i18next";

const About = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);
  const isMobile = useMobileSize(576);
  return (
    <>
      <Helmet>
        <title>{siteTitle} - About Us</title>
        <meta name="description" content={siteTitle + " - About us page"} />
        <meta name="keywords" content="About us"></meta>
      </Helmet>

      <SiteBreadcrumb title="about us" parent="Home" />

      <section className="about-page ">
        <div className="custom-container">
          <h4 className="text-center">{t("about our store")}</h4>
          <div className="banner-section">
            <img
              src={`/images/about-us${isMobile ? "-mobile" : ""}.jpg`}
              className="img-fluid w-100"
              alt=""
            />
            {/* <Media src={aboutImg} className="img-fluid w-100" alt="" /> */}
          </div>
        </div>
        <div className="custom-container" style={{ maxWidth: "900px" }}>
          <Row>
            <Col lg="12">
              {/* <h4>About our Store</h4> */}
              <p className="mb-2">
                {t("about us content 0")}
                <b>V1Hub Shop </b>,{t("about us content 1")}
              </p>
              <p className="mb-2">
                <b>{t("about us content 2-bold")}</b>
                {t("about us content 2")}
              </p>
              <p className="mb-2">
                <br></br>
                <b>{t("about us content 3")} </b>
              </p>
              <p className="mb-2">
                <b>{t("about us content 4-bold")}</b> {t("about us content 4")}
                <br />
                <b>{t("about us content 5-bold")}</b> {t("about us content 5")}
                <br />
                <b>{t("about us content 6-bold")}</b>
                {t("about us content 6")}
              </p>
              <br></br>
              <p>{t("about us content 7")}</p>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default About;
