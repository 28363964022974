import About from "web/views/about/About";
import Campaign from "web/views/campaign/Campaign";
import Cart from "web/views/checkout/Cart";
import Checkout from "web/views/checkout/Checkout";
import BrainTreeUrl from "web/views/checkout/components/paybraintree/BrainTreeUrl";
import RedirectUrl from "web/views/checkout/components/payos/RedirectUrl";
import VerifyCreateOrder from "web/views/checkout/components/VerifyCreateOrder";
import Refresh from "web/views/common/Refresh";
import CompareList from "web/views/compare/Index";
import ContactUs from "web/views/contactUs/Index";
import FaqPage from "web/views/faq/FaqPage";
import Home from "web/views/home/Index";
import Login from "web/views/login/Login";
import ResetPassword from "web/views/login/ResetPassword";
import AllProducts from "web/views/products/AllProducts";
import ProductDetail from "web/views/products/ProductDetail";
import ShippingInfo from "web/views/policy/ShippingInfo";
import BecomeVendor from "web/views/signup/BecomeVendor";
import OrdersHistory from "web/views/signup/OrdersHistory";
import Signup from "web/views/signup/Signup";
import UpdateProfile from "web/views/signup/UpdateProfile";
import PrivacyPolicy from "web/views/policy/PrivacyPolicy";
import TermCondition from "web/views/policy/TermCondition";
import RefundPolicy from "web/views/policy/RefundPolicy";
import OrderProcess from "web/views/policy/OrderProcess";
import BulkProduction from "web/views/policy/BulkProduction";
import ChangePassword from "web/views/login/ChangePassword";
import OrdersHistoryDetail from "web/views/signup/OrdersHistoryDetail";

export const HomeRoutes = [
  { path: "/:langCode/", element: <Home /> },
  { path: "/", element: <Home /> },
  { path: "*", element: <Home /> },
];
export const UserRoutes = [
  {
    path: "/all-products/:category_id/:category_name",
    element: <AllProducts />,
  },
  {
    path: "/product-detail/:product_id/:category/:product_name",
    element: <ProductDetail />,
  },
  { path: "/contact-us", element: <ContactUs /> },
  { path: "/login", element: <Login /> },
  { path: "/signup", element: <Signup /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/change-password", element: <ChangePassword /> },

  { path: "/shipping-information", element: <ShippingInfo /> },
  { path: "/privacy-policy", element: <PrivacyPolicy /> },
  { path: "/terms-and-conditions", element: <TermCondition /> },
  { path: "/refund-policy", element: <RefundPolicy /> },
  { path: "/order-process", element: <OrderProcess /> },
  { path: "/bulk-production", element: <BulkProduction /> },

  { path: "/about", element: <About /> },
  { path: "/faq", element: <FaqPage /> },
  { path: "/checkout", element: <Checkout /> },
  { path: "/braintree/new", element: <BrainTreeUrl /> },
  { path: "/payos/redirect-url", element: <RedirectUrl /> },
  { path: "/create-order/verify", element: <VerifyCreateOrder /> },
  { path: "/cart", element: <Cart /> },
  { path: "/update-profile", element: <UpdateProfile /> },
  {
    path: "/campaign/:campaign_id/:campaign_main_title",
    element: <Campaign />,
  },
  { path: "/orders-history", element: <OrdersHistory /> },
  {
    path: "/orders-history/:order_id",
    element: <OrdersHistoryDetail />,
  },
  { path: "/refresh", element: <Refresh /> },
  { path: "/become-seller", element: <BecomeVendor /> },
  { path: "/compare", element: <CompareList /> },

  {
    path: "/:langCode/all-products/:category_id/:category_name",
    element: <AllProducts />,
  },
  {
    path: "/:langCode/product-detail/:product_id/:category/:product_name",

    element: <ProductDetail />,
  },
  { path: "/:langCode/contact-us", element: <ContactUs /> },
  { path: "/:langCode/about", element: <About /> },
  { path: "/:langCode/login", element: <Login /> },
  { path: "/:langCode/signup", element: <Signup /> },
  { path: "/:langCode/reset-password", element: <ResetPassword /> },
  { path: "/:langCode/change-password", element: <ChangePassword /> },
  { path: "/:langCode/checkout", element: <Checkout /> },

  { path: "/:langCode/shipping-information", element: <ShippingInfo /> },
  { path: "/:langCode/privacy-policy", element: <PrivacyPolicy /> },
  { path: "/:langCode/terms-and-conditions", element: <TermCondition /> },
  { path: "/:langCode/refund-policy", element: <RefundPolicy /> },
  { path: "/:langCode/order-process", element: <OrderProcess /> },
  { path: "/:langCode/bulk-production", element: <BulkProduction /> },

  { path: "/:langCode/faq", element: <FaqPage /> },
  { path: "/:langCode/cart", element: <Cart /> },
  { path: "/:langCode/update-profile", element: <UpdateProfile /> },
  {
    path: "/:langCode/campaign/:campaign_id/:campaign_main_title",

    element: <Campaign />,
  },
  { path: "/:langCode/orders-history", element: <OrdersHistory /> },
  {
    path: "/:langCode/orders-history/:order_id",
    element: <OrdersHistoryDetail />,
  },
  { path: "/:langCode/refresh", element: <Refresh /> },
  { path: "/:langCode/become-seller", element: <BecomeVendor /> },
  { path: "/:langCode/compare", element: <CompareList /> },
];
