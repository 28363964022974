import { Helmet } from "react-helmet";
import { Col, Row } from "reactstrap";
import Config from "../../../helpers/Config";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import { useState } from "react";
import { t } from "i18next";
const shippingTimes = [
  {
    id: 1,
    area: "north america",
    time: "15",
    unit: "days",
  },
  {
    id: 2,
    area: "european union",
    time: "15",
    unit: "days",
  },
  {
    id: 3,
    area: "vietnam & south east asia",
    time: "5",
    unit: "days",
  },
  {
    id: 4,
    area: "rest of the world",
    time: "15",
    unit: "days",
  },
];

const shippingCosts = [
  {
    id: 1,
    area: "north america",
    cost: "15$",
  },
  {
    id: 2,
    area: "european union",
    cost: "15$",
  },
  {
    id: 3,
    area: "vietnam & south east asia",
    cost: "15$",
  },
  {
    id: 4,
    area: "rest of the world",
    cost: "10$",
  },
];

const ShippingInfo = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);
  return (
    <>
      <Helmet>
        <title>{siteTitle} - Shipping and Production Information</title>
        <meta
          name="description"
          content={siteTitle + " - Shipping and Production Information"}
        />
        <meta
          name="keywords"
          content="Shipping and Production Information"
        ></meta>
      </Helmet>

      <SiteBreadcrumb
        title="shipping and production information"
        parent="Home"
      />

      <section className="about-page  mt-2 mt-md-4">
        <div className="custom-container" style={{ maxWidth: "900px" }}>
          <Row>
            <Col lg="12" className="mb-2">
              <h4 className="mb-3">{t("production times")}</h4>
              <p className="mb-2">{t("in stock itself")}</p>
              <p className="mb-2">
                {t("pre-order products order")}{" "}
                <b>{t("our current production time")}</b>{" "}
                {t("please note that this time")}
                <i>{t("this does not include shipping times")}</i>{" "}
                {t("you can check estimated shipping times")}
              </p>
              <p className="mb-2">{t("important: delivery times")}</p>
            </Col>
            <Col lg="12" className="mb-2">
              <h4 className="mb-3">{t("shipping times")}</h4>
              <div className="mb-2">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>{t("shipping destination")}</th>
                      <th>
                        {t("shipping time")} (
                        {t("worldwide delivery times may vary")}){" "}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {shippingTimes.map((item, idx) => (
                      <tr>
                        <td>{t(item.area)}</td>
                        <td>
                          {item.time} {t(item.unit)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <p className="mb-2">
                <i>{t("timings can not be guaranteed")}</i>
              </p>
            </Col>
            <Col lg="12">
              <h4 className="mb-3">{t("shipping costs")}</h4>
              <div className="mb-2">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>{t("shipping destination")}</th>
                      <th>{t("cost")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {shippingCosts.map((item, idx) => (
                      <tr>
                        <td>{t(item.area)}</td>
                        <td>{item.cost}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <p className="mb-2">{t("please don't hesitate to contact us")}</p>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default ShippingInfo;
