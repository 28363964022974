import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Col, Container, Media, Row } from "reactstrap";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import { getLanguageCodeFromSession } from "../../../helpers/CommonHelper";
import Config from "../../../helpers/Config";
import {
  makeAnyStringLengthShort,
  replaceWhiteSpacesWithDashSymbolInUrl,
} from "../../../helpers/ConversionHelper";
import { reduxStore } from "stateManagment/reduxStore";
import { useSelector } from "react-redux";

var settings = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 6,
  slidesToScroll: 6,
  responsive: [
    {
      breakpoint: 1367,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        infinite: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
  ],
};

const PopularCategories = () => {
  const [PopularCategoriesList, setPopularCategories] = useState([]);
  const langCode = useSelector((state) => state.commonReducer.langCodeInRedux);

  useEffect(() => {
    const getPopularCategories = async () => {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const param = {
        requestParameters: {
          PageNo: 1,
          PageSize: 20,
          recordValueJson: "[]",
          LanguageId: langCode,
        },
      };

      const response = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_POPULAR_CATEGORIES"],
        null,
        param,
        headers,
        "POST",
        true
      );
      if (response !== null && response.data !== null) {
        setPopularCategories(JSON.parse(response.data.data));
      }
    };

    getPopularCategories().catch(console.error);
  }, [langCode]);

  return (
    <>
      {PopularCategoriesList !== undefined &&
      PopularCategoriesList !== null &&
      PopularCategoriesList.length > 0 ? (
        <>
          <div className="title6 title-popular">
            <h4>{t("popular categories")}</h4>
          </div>

          <section className="rounded-category rounded-category-inverse">
            <Container>
              <Row>
                <Col>
                  <div className="slide-6 no-arrow">
                    <Slider {...settings}>
                      {PopularCategoriesList &&
                        PopularCategoriesList.map((item, i) => (
                          <div key={i}>
                            <div className="category-contain">
                              <Link
                                to={`/${getLanguageCodeFromSession()}/all-products/${
                                  item.CategoryID ?? 0
                                }/${replaceWhiteSpacesWithDashSymbolInUrl(
                                  item.Name
                                )}`}
                              >
                                <div className="img-wrapper">
                                  <Media
                                    src={item.AttachmentURL}
                                    alt="category"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                    className=""
                                    title={makeAnyStringLengthShort(
                                      item.Name,
                                      22
                                    )}
                                  />
                                </div>
                                <div>
                                  <div className="btn-rounded">
                                    {(() => {
                                      let allProductsUrl = `/${getLanguageCodeFromSession()}/all-products/${
                                        item.CategoryID ?? 0
                                      }/${replaceWhiteSpacesWithDashSymbolInUrl(
                                        item.Name
                                      )}`;

                                      return (
                                        <>
                                          <Link
                                            to={allProductsUrl}
                                            style={{
                                              textDecoration: "none",
                                            }}
                                          >
                                            {makeAnyStringLengthShort(
                                              item.Name,
                                              22
                                            )}
                                          </Link>
                                        </>
                                      );
                                    })()}
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        ))}
                    </Slider>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default PopularCategories;
