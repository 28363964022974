import { Outlet } from "react-router-dom";
import LoadingScreen from "../shared/LoadingScreen";
import Footer from "./Footer";

export default function HomeLayout() {
  return (
    <div>
      <LoadingScreen />
      <div className="mobile-fix-option"></div>
      <Outlet />
      <Footer layoutLogo="layout-2" />
    </div>
  );
}
