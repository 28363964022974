import { Helmet } from "react-helmet";
import { Col, Row } from "reactstrap";
import Config from "../../../helpers/Config";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import { useState } from "react";
import { t } from "i18next";

const contents = [
  {
    content: [
      "refund policy content 1",
      "refund policy content 2",
      "refund policy content 3",
      "refund policy content 4",
      "refund policy content 5",
    ],
  },

  {
    title: "refund policy content 6",
    content: ["refund policy content 7"],
  },

  {
    title: "refund policy content 8",
    content: ["refund policy content 9"],
  },
];

const RefundPolicy = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);
  return (
    <>
      <Helmet>
        <title>{siteTitle} - Refund Policy</title>
        <meta name="description" content={siteTitle + " - Refund Policy"} />
        <meta name="keywords" content="Refund Policy"></meta>
      </Helmet>

      <SiteBreadcrumb title="refund policy" parent="Home" />

      <section className="about-page  mt-2 mt-md-4">
        <div className="custom-container" style={{ maxWidth: "900px" }}>
          <Row>
            {contents.map((content, index) => (
              <Col xs="12" className="mb-2 mt-1">
                <h4 className="mb-2">{t(content.title)}</h4>
                {content.list && content.list.length > 0 ? (
                  <ul style={{ listStyleType: "disc", paddingLeft: "32px" }}>
                    {content.list.map((item, index) => (
                      <li
                        key={index}
                        className="mb-2"
                        style={{ display: "list-item" }}
                      >
                        <p>{t(item)}</p>
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
                {content.content &&
                  content.content.length > 0 &&
                  content.content.map((item, index) => (
                    <p className="mb-2" key={index}>
                      {t(item)}
                    </p>
                  ))}
              </Col>
            ))}
          </Row>
        </div>
      </section>
    </>
  );
};

export default RefundPolicy;
