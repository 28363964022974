import useMobileSize from "helpers/utils/isMobile";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Col,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import {
  AddCustomerWishList,
  AddProductToCart,
} from "../../../helpers/CartHelper";
import {
  formatPrice,
  GetDefaultCurrencyCode,
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
} from "../../../helpers/CommonHelper";
import Config from "../../../helpers/Config";
import { LOADER_DURATION } from "../../../helpers/Constants";
import {
  calculatePriceDiscountPercentage,
  makePriceRoundToTwoPlaces,
  makeProductShortDescription,
  setProductDescriptionImagesUrl,
} from "../../../helpers/ConversionHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";
import {
  showErrorMsg,
  showInfoMsg,
  showSuccessMsg,
  validateAnyFormField,
} from "../../../helpers/ValidationHelper";
import rootAction from "../../../stateManagment/actions/rootAction";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import ProductDetailImages from "../../components/products/ProductDetailImages";
import ProductRatingStars from "../../components/products/ProductRatingStars";
import ProductService from "../../components/products/ProductService";
import ProductVariants from "../../components/products/ProductVariants";
import SidePopularProducts from "../../components/products/SidePopularProducts";
import SizeGuide from "../../components/shared/SizeGuide";

const ProductDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);
  const isMobile = useMobileSize(576);

  const [qty, setQuantity] = useState(1);
  const [max, setMax] = useState(1);
  const [min, setMin] = useState(1);
  const [ActiveSize, setActiveSize] = useState({
    SizeID: 0,
    ShortName: "",
  });

  const [ActiveColor, setActiveColor] = useState({
    ColorID: 0,
    ColorName: "",
  });
  const currency = useRef(GetDefaultCurrencyCode());
  const langCode = useRef(getLanguageCodeFromSession());
  const [sizeGuide, setSizeGuide] = useState(false);
  const [productDetail, setProductDetail] = useState({});
  const [productReviews, setProductReviews] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [allProductImages, setAllProductImages] = useState([]);
  const [filterProductImages, setFilterProductImages] = useState([]);
  const [productAllAttributes, setProductAllAttributes] = useState([]);
  const [productSelectedAttributes, setProductSelectedAttributes] = useState(
    []
  );
  const [showProductVariantsPopup, setShowProductVariantsPopup] =
    useState(false);
  const [productActualPrice, setProductActualPrice] = useState(0.0);
  const [productDiscountedPrice, setProductDiscountedPrice] = useState(0.0);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

  //--set review variables
  const [ReviewerName, setReviewerName] = useState("");
  const [ReviewerEmail, setReviewerEmail] = useState("");
  const [ReviewTitle, setReviewTitle] = useState("");
  const [ReviewBody, setReviewBody] = useState("");
  const [ReviewRating, setReviewRating] = useState(1);

  //--set product id from url
  const params = useParams();
  const [ProductId, setProductId] = useState(params.product_id ?? 0);

  const DecreaseItem = () => {
    if (qty > 1) {
      setQuantity(qty - 1);
    }
  };

  const IncrementItem = () => {
    if (
      productDetail.OrderMaximumQuantity !== undefined &&
      productDetail.OrderMaximumQuantity !== null &&
      productDetail.OrderMaximumQuantity > 0
    ) {
      if (qty + 1 > productDetail.OrderMaximumQuantity) {
        showErrorMsg(
          `Can not add more than ${productDetail.OrderMaximumQuantity} for this product`
        );
      } else {
        setQuantity(qty + 1);
      }
    } else {
      if (qty < 10) {
        setQuantity(qty + 1);
      }
    }
  };

  const [activeTab, setActiveTab] = useState("1");
  // const [filterOpen, setFilterOpen] = useState(false);

  const [stock, setStock] = useState("InStock");

  const handleClick = (newRating) => {
    setReviewRating(newRating);
  };

  const openSizeGuide = () => {
    setSizeGuide(true);
  };

  const closeSizeGuide = () => {
    setSizeGuide(false);
  };

  const openProductVariants = () => {
    setShowProductVariantsPopup(true);
  };

  const closeProductVariantPopup = () => {
    setShowProductVariantsPopup(false);
  };

  const setProductVariantsFromPopup = (PrimaryKeyValue, ProductAttributeID) => {
    let tempProdAttr = [];
    tempProdAttr = productSelectedAttributes;

    let isAttributeExists = tempProdAttr?.find(
      (x) => x.ProductAttributeID == ProductAttributeID
    );

    //--If attribute already exists then just update its value
    if (
      isAttributeExists !== null &&
      isAttributeExists !== undefined &&
      isAttributeExists.ProductAttributeID > 0
    ) {
      let objIndex = tempProdAttr.findIndex(
        (obj) => obj.ProductAttributeID == ProductAttributeID
      );
      tempProdAttr[objIndex].PrimaryKeyValue = PrimaryKeyValue;
    } else {
      tempProdAttr.push({
        ProductId: ProductId,
        ProductAttributeID: ProductAttributeID,
        PrimaryKeyValue: PrimaryKeyValue,
      });
    }

    //--Set in product selected attributes
    setProductSelectedAttributes(tempProdAttr);

    //--Set any extra price if associated with this attribute
    let additionalPrice = 0;
    for (let index = 0; index < tempProdAttr.length; index++) {
      let priceData = productAllAttributes?.find(
        (x) =>
          x.ProductAttributeID == tempProdAttr[index].ProductAttributeID &&
          x.PrimaryKeyValue == tempProdAttr[index].PrimaryKeyValue
      );
      if (
        priceData !== null &&
        priceData !== undefined &&
        priceData.AdditionalPrice !== undefined &&
        priceData.AdditionalPrice > 0
      ) {
        additionalPrice = additionalPrice + priceData.AdditionalPrice;
      }
    }

    //--Set product actual price
    setProductActualPrice(
      makePriceRoundToTwoPlaces(productDetail.Price + additionalPrice)
    );

    //--Set product discounted price
    setProductDiscountedPrice(
      makePriceRoundToTwoPlaces(productDetail.DiscountedPrice + additionalPrice)
    );

    //--Set Product images according to product color
    if (ProductAttributeID == Config.PRODUCT_ATTRIBUTE_ENUM["Color"]) {
      mappedProductImagesWithColor(PrimaryKeyValue);
    }

    console.log(productSelectedAttributes);
  };

  const mappedProductImagesWithColor = (ColorId) => {
    try {
      const filteredItems = allProductImages.filter(
        ({ ColorID }) => ColorID == ColorId
      );
      if (
        filteredItems !== null &&
        filteredItems !== undefined &&
        filteredItems.length > 0
      ) {
        setFilterProductImages(filteredItems);
      }
    } catch (error) {
      console.error(error.message);

      setFilterProductImages(allProductImages);
    }
  };

  const HandleAddToCart = (isBuyNowBtn) => {
    if (
      productDetail == undefined ||
      productDetail.ProductId == undefined ||
      productDetail.ProductId < 1
    ) {
      showErrorMsg("Invalid product!");
      return false;
    }

    if (
      productDetail?.StockQuantity !== null &&
      productDetail?.StockQuantity !== undefined &&
      productDetail.StockQuantity < 1
    ) {
      showInfoMsg("Product is out of stock. Can't add it in the cart!");
      return false;
    }

    //--check if size selected
    if (
      productDetail?.ProductSizesJson?.length !== undefined &&
      productDetail?.ProductSizesJson?.length > 0
    ) {
      if (ActiveSize.SizeID == undefined || ActiveSize.SizeID < 1) {
        showInfoMsg("Select size of product!");
        return false;
      }
    }

    //--check if color selected
    if (
      productDetail?.ProductColorsJson?.length !== undefined &&
      productDetail?.ProductColorsJson.length > 0
    ) {
      if (ActiveColor.ColorID == undefined || ActiveColor.ColorID < 1) {
        showInfoMsg("Select color of product!");
        return false;
      }
    }

    //--validate all others attributes except color and size because its already validated above
    let localAttributes = productAllAttributes?.filter(
      (x) =>
        x.ProductAttributeID !== Config.PRODUCT_ATTRIBUTE_ENUM["Color"] &&
        x.ProductAttributeID !== Config.PRODUCT_ATTRIBUTE_ENUM["Size"]
    );
    for (let index = 0; index < localAttributes.length; index++) {
      const elementAttr = localAttributes[index];
      if (
        elementAttr?.IsRequiredAttribute !== undefined &&
        elementAttr?.IsRequiredAttribute == true
      ) {
        if (
          !productSelectedAttributes.some(
            (x) => x.ProductAttributeID === elementAttr.ProductAttributeID
          )
        ) {
          showInfoMsg(
            "Please select " + elementAttr.AttributeDisplayName + " variant!"
          );
          return false;
        }
      }
    }

    //--check if quantity selected
    if (qty == undefined || qty < 1) {
      showInfoMsg("Select quantity!");
      return false;
    }

    let defaultImage =
      productDetail?.ProductImagesJson?.length > 0
        ? productDetail.ProductImagesJson[0].AttachmentURL
        : "";
    let cartItems = AddProductToCart(
      ProductId,
      qty,
      productSelectedAttributes,
      defaultImage,
      currency.current,
      langCode.current
    );

    // reduxStore.dispatch(rootAction.cartAction.setCustomerCart(cartItems));
    // reduxStore.dispatch(rootAction.cartAction.SetTotalCartItems(JSON.parse(cartItems).length));

    dispatch(rootAction.cartAction.setCustomerCart(cartItems));
    dispatch(
      rootAction.cartAction.SetTotalCartItems(JSON.parse(cartItems).length)
    );

    if (isBuyNowBtn !== undefined && isBuyNowBtn == true) {
      navigate("/" + getLanguageCodeFromSession() + "/cart");
    }
  };

  const SubmitReviewForm = async () => {
    let isValid = false;
    let validationArray = [];

    //--validation for name
    isValid = validateAnyFormField(
      "Name",
      ReviewerName,
      "text",
      null,
      200,
      true
    );
    if (isValid == false) {
      validationArray.push({
        isValid: isValid,
      });
    }

    //--validation for email
    isValid = validateAnyFormField(
      "Email",
      ReviewerEmail,
      "email",
      null,
      200,
      true
    );
    if (isValid == false) {
      validationArray.push({
        isValid: isValid,
      });
    }

    //--validation for title of review
    isValid = validateAnyFormField(
      "Review Title",
      ReviewTitle,
      "text",
      null,
      200,
      true
    );
    if (isValid == false) {
      validationArray.push({
        isValid: isValid,
      });
    }

    //--validation for body of review
    isValid = validateAnyFormField(
      "Review Body",
      ReviewBody,
      "text",
      null,
      200,
      true
    );
    if (isValid == false) {
      validationArray.push({
        isValid: isValid,
      });
    }

    //--validation for product id of review
    isValid = validateAnyFormField(
      "Product Id",
      ProductId,
      "number",
      null,
      200,
      true
    );
    if (isValid == false) {
      validationArray.push({
        isValid: isValid,
      });
    }

    //--check if any field is not valid
    if (validationArray !== null && validationArray.length > 0) {
      isValid = false;
      return false;
    } else {
      isValid = true;
    }

    if (isValid) {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const param = {
        requestParameters: {
          ProductId: ProductId,
          ReviewerName: ReviewerName,
          ReviewerEmail: ReviewerEmail,
          ReviewTitle: ReviewTitle,
          ReviewBody: ReviewBody,
          ReviewRating: ReviewRating,
        },
      };

      //--make api call for saving review data
      const response = await MakeApiCallAsync(
        Config.END_POINT_NAMES["INSERT_PRODUCT_REVIEW"],
        null,
        param,
        headers,
        "POST",
        true
      );
      if (response !== null && response.data !== null) {
        let detail = JSON.parse(response.data.data);
        if (detail[0].ResponseMsg == "Saved Successfully") {
          showSuccessMsg("Your review submitted successfully!");

          //--Empty form
          await setReviewerName("");
          await setReviewerEmail("");
          await setReviewTitle("");
          await setReviewBody("");
        } else {
          showErrorMsg("An error occurred. Please try again later!");
        }
      }
    }
  };

  const HandleCustomerWishList = () => {
    let defaultImageWishList =
      productDetail?.ProductImagesJson?.length > 0
        ? productDetail.ProductImagesJson[0].AttachmentURL
        : "";
    let customerWishList = AddCustomerWishList(
      ProductId,
      productDetail?.ProductName,
      productDetail.Price,
      productDetail.DiscountedPrice,
      productDetail.DiscountId,
      productDetail.IsDiscountCalculated,
      productDetail.CouponCode,
      ActiveSize.SizeID,
      ActiveSize.ShortName,
      0,
      "",
      qty,
      defaultImageWishList,
      currency.current
    );

    //--store in storage
    localStorage.setItem("customerWishList", customerWishList);
    dispatch(rootAction.cartAction.setCustomerWishList(customerWishList));
  };

  useEffect(() => {
    // declare the data fetching function
    const getProductDetail = async () => {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const param = {
        requestParameters: {
          ProductId: ProductId,
          recordValueJson: "[]",
          Currency: currency.current,
          LanguageId: getLanguageCodeFromSession(),
        },
      };

      //--Get product detail
      const response = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_PRODUCT_DETAIL"],
        null,
        param,
        headers,
        "POST",
        true
      );
      if (response !== null && response.data !== null) {
        let detail = JSON.parse(response.data.data);
        // console.log("Product detail: ");
        // console.log(detail);
        await setProductDetail(detail[0]);

        //--Set product All images
        await setAllProductImages(detail[0]?.ProductImagesJson);

        //--Set product filtered images
        await setFilterProductImages(detail[0]?.ProductImagesJson);

        //--Set product actual price
        await setProductActualPrice(detail[0].Price);

        //--Set product discounted price
        await setProductDiscountedPrice(detail[0].DiscountedPrice);
      }

      //--Get product reviews
      const responseReviews = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_PRODUCT_REVIEWS"],
        null,
        param,
        headers,
        "POST",
        true
      );
      if (responseReviews !== null && responseReviews.data !== null) {
        await setProductReviews(JSON.parse(responseReviews.data.data));
      }

      //--Get payment methods
      const responsePaymentMethods = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_PAYMENT_METHODS"],
        null,
        param,
        headers,
        "POST",
        true
      );
      if (
        responsePaymentMethods !== null &&
        responsePaymentMethods.data !== null
      ) {
        await setPaymentMethods(JSON.parse(responsePaymentMethods.data.data));
      }
    };

    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    getProductDetail().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);

    //--scroll page top top becuase the product detail page giving issue
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, 500);
  }, []);

  useEffect(() => {
    // declare the data fetching function
    const dataOperationFunc = async () => {
      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["ProductDetail"],
        null
      );
      if (
        arryRespLocalization !== null &&
        arryRespLocalization !== undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    // call the function
    dataOperationFunc().catch(console.error);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {siteTitle} -{" "}
          {productDetail?.MetaTitle && productDetail?.MetaTitle !== undefined
            ? productDetail?.MetaTitle
            : t("product detail")}
        </title>
        <meta
          name="description"
          content={
            siteTitle + " - " + productDetail?.MetaDescription !== undefined
              ? productDetail?.MetaDescription
              : "product description"
          }
        />
        <meta
          name="keywords"
          content={
            productDetail?.MetaKeywords !== undefined
              ? productDetail?.MetaKeywords
              : "product description"
          }
        />
      </Helmet>

      <SiteBreadcrumb title="product detail" parent="Home" />
      <section>
        <div className="collection-wrapper mb-3 pb-3 mt-2">
          <div className="custom-container">
            <Row className="flex-column-reverse flex-md-row">
              <Col
                xs="12"
                lg="3"
                xxl="2.5"
                className="side-left-product-detail"
              >
                <div className="side-left-product-detail-content">
                  <ProductService />
                  <SidePopularProducts />
                </div>
              </Col>
              <Col
                lg="9"
                xxl="9.5"
                xs="12"
                className="px-3 ps-md-3 side-right-product-detail"
              >
                <Row className="product-detail-info">
                  <Col lg="6" className="ps-0">
                    {filterProductImages?.length > 0 ? (
                      <ProductDetailImages
                        ProductImages={filterProductImages}
                      />
                    ) : (
                      <></>
                    )}
                  </Col>
                  <Col lg="6" className="rtl-text product-detail-right">
                    <div className="product-right">
                      <h2>{productDetail?.ProductName}</h2>
                      {productDetail?.Rating !== undefined &&
                      productDetail.Rating !== null ? (
                        <div className="product-det-rating-sec">
                          <ProductRatingStars Rating={productDetail.Rating} />
                          <Link to="#" className="rating-count">
                            {productDetail.TotalReviews}{" "}
                            <span id="lbl_prd_det_reviews">{t("reviews")}</span>
                          </Link>
                        </div>
                      ) : (
                        <></>
                      )}
                      <h4>
                        {productDiscountedPrice !== undefined &&
                        productDiscountedPrice > 0 ? (
                          <>
                            <del>
                              {formatPrice(
                                productActualPrice,
                                productDetail.Currency
                              )}
                            </del>
                            <span>
                              {calculatePriceDiscountPercentage(
                                productActualPrice,
                                productDiscountedPrice
                              )}
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                      </h4>
                      <h3>
                        {formatPrice(
                          productDiscountedPrice !== undefined &&
                            productDiscountedPrice > 0
                            ? productDiscountedPrice
                            : productActualPrice,
                          productDetail.Currency
                        )}
                      </h3>

                      {/* {swatch ? <ImageSwatch item={item} changeColorVar={changeColorVar} /> : ""} */}
                      <div className="product-description border-product">
                        <div className="product-info-custom">
                          <div className="product-info-custom-item">
                            <span className="product-info-custom-label">
                              {t("vendor")}:
                            </span>
                            <span className="product-info-custom-value">
                              {productDetail?.VendorName}
                            </span>
                          </div>

                          <div className="product-info-custom-item">
                            <span className="product-info-custom-label">
                              {t("availability")}:
                            </span>

                            {(() => {
                              if (
                                productDetail?.DisplayStockQuantity !==
                                  undefined &&
                                productDetail.DisplayStockQuantity == true
                              ) {
                                if (
                                  productDetail.StockQuantity !== null &&
                                  productDetail.StockQuantity !== undefined &&
                                  productDetail.StockQuantity > 0
                                ) {
                                  return (
                                    <>
                                      <span
                                        id="lbl_prd_det_instock"
                                        style={{ color: "#4CBB17" }}
                                      >
                                        {t("in stock")}
                                      </span>

                                      <span className="product-info-custom-value">
                                        ({productDetail.StockQuantity}{" "}
                                        {t("items")})
                                      </span>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <span className="product-info-custom-value">
                                        {t("out of stock")}
                                      </span>
                                    </>
                                  );
                                }
                              } else {
                                return (
                                  <>
                                    <span className="product-info-custom-value">
                                      --
                                    </span>
                                  </>
                                );
                              }
                            })()}
                          </div>

                          <div className="product-info-custom-item">
                            <span className="product-info-custom-label">
                              {t("brand")}:
                            </span>
                            <span className="product-info-custom-value">
                              {productDetail?.ManufacturerName}
                            </span>
                          </div>
                        </div>
                      </div>

                      {productDetail?.ProductColorsJson !== undefined &&
                      productDetail?.ProductColorsJson !== null &&
                      productDetail?.ProductColorsJson.length > 0 ? (
                        <div className="product-description border-product">
                          {" "}
                          <h6 className="product-title">select color</h6>
                          <ul class="color-variant">
                            {productDetail?.ProductColorsJson?.map(
                              (item, idx) => (
                                <li
                                  key={idx}
                                  title="color of product"
                                  className={
                                    ActiveColor.ColorID === item.ColorID
                                      ? "product-color-cell-active"
                                      : ""
                                  }
                                  style={{ backgroundColor: `${item.HexCode}` }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setActiveColor({
                                      ColorID: item.ColorID,
                                      ColorName: item.ColorName,
                                    });
                                    setProductVariantsFromPopup(
                                      item.ColorID,
                                      Config.PRODUCT_ATTRIBUTE_ENUM["Color"]
                                    );
                                  }}
                                ></li>
                              )
                            )}
                          </ul>
                        </div>
                      ) : (
                        <></>
                      )}

                      {productDetail?.ProductSizesJson !== undefined &&
                      productDetail?.ProductSizesJson !== null &&
                      productDetail?.ProductSizesJson.length > 0 ? (
                        <div className="product-description border-product">
                          <h6 className="product-title size-text">
                            {t("select size")}{" "}
                            <span>
                              <a
                                data-toggle="modal"
                                data-target="#sizemodal"
                                onClick={(e) => {
                                  e.preventDefault();
                                  openSizeGuide();
                                }}
                              >
                                {t("size chart")}
                              </a>
                            </span>
                          </h6>
                          <div className="size-box-custom">
                            <ul>
                              {productDetail?.ProductSizesJson?.map(
                                (item, idx) => (
                                  <li
                                    key={idx}
                                    className={
                                      ActiveSize.SizeID === item.SizeID
                                        ? "active"
                                        : null
                                    }
                                  >
                                    <Link
                                      to="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setActiveSize({
                                          SizeID: item.SizeID,
                                          ShortName: item.ShortName,
                                        });
                                        setProductVariantsFromPopup(
                                          item.SizeID,
                                          Config.PRODUCT_ATTRIBUTE_ENUM["Size"]
                                        );
                                      }}
                                    >
                                      {item.ShortName}
                                    </Link>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {productAllAttributes !== undefined &&
                      productAllAttributes?.filter(
                        (x) =>
                          x.ProductAttributeID !==
                            Config.PRODUCT_ATTRIBUTE_ENUM["Color"] &&
                          x.ProductAttributeID !==
                            Config.PRODUCT_ATTRIBUTE_ENUM["Size"]
                      ).length > 0 ? (
                        <div className="product-description border-product">
                          <div className="size-box-custom mt-3">
                            <div class="col-sm-4">
                              <Link
                                to="#"
                                className="btn btn-white btn-outline"
                                style={{ padding: "16px 18px" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  openProductVariants();
                                }}
                              >
                                {t("select variants")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className="product-description border-product">
                        {stock !== "InStock" ? (
                          <span className="instock-cls">{stock}</span>
                        ) : (
                          ""
                        )}
                        <h6 className="product-title">{t("quantity")}</h6>
                        <div className="qty-box">
                          <div className="input-group">
                            <span className="input-group-prepend">
                              <button
                                type="button"
                                className="btn btn-sm btn-outline"
                                data-type="minus"
                                data-field=""
                                onClick={DecreaseItem}
                              >
                                <i class="fa fa-minus" aria-hidden="true"></i>
                              </button>
                            </span>
                            {/* <span className="qty-number">{qty}</span> */}
                            <Input
                              type="text"
                              name="quantity"
                              className="form-control input-number"
                              value={qty}
                              min={min}
                              max={max}
                              onChange={(e) => setQuantity(e.target.value)}
                            />
                            <span className="input-group-prepend">
                              <button
                                type="button"
                                className="btn btn-sm btn-outline"
                                data-type="plus"
                                data-field=""
                                onClick={IncrementItem}
                              >
                                <i class="fa fa-plus" aria-hidden="true"></i>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="product-buttons product-icon">
                        <button
                          className="wishlist-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            HandleCustomerWishList();
                          }}
                        >
                          <i className="fa fa-heart-o"></i>
                          <span className="title-font">
                            {t("add to wishlist")}
                          </span>
                        </button>
                        <div className="product-buttons-group">
                          <Link
                            to="#"
                            className="btn btn-normal btn-outline"
                            id="lbl_prd_det_addcart"
                            onClick={(e) => {
                              e.preventDefault();
                              HandleAddToCart(false);
                            }}
                          >
                            {t("add to cart")}
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-normal"
                            onClick={(e) => {
                              e.preventDefault();
                              HandleAddToCart(true);
                            }}
                          >
                            {t("buy now")}
                          </Link>
                        </div>
                      </div>
                      <div className="border-product">
                        <h6 className="product-title">{t("product detail")}</h6>
                        <p>
                          {makeProductShortDescription(
                            productDetail?.ShortDescription,
                            245
                          )}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <section className="tab-product tab-exes creative-card creative-inner mb-0">
                  <Row>
                    <Col sm="12" lg="12">
                      <Nav
                        tabs
                        className="nav-material"
                        id="top-tab"
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            className={activeTab === "1" ? "active" : ""}
                            onClick={() => setActiveTab("1")}
                          >
                            {t("description")}
                            <div className="material-border"></div>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={activeTab === "2" ? "active" : ""}
                            onClick={() => setActiveTab("2")}
                          >
                            {t("additional information")}
                            <div className="material-border"></div>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={activeTab === "3" ? "active" : ""}
                            onClick={() => setActiveTab("3")}
                          >
                            {t("shipping")}
                            <div className="material-border"></div>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={activeTab === "4" ? "active" : ""}
                            onClick={() => setActiveTab("4")}
                          >
                            {t("why buy from us")}
                            <div className="material-border"></div>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={activeTab === "5" ? "active" : ""}
                            onClick={() => setActiveTab("5")}
                          >
                            {t("reviews")}
                            <div className="material-border"></div>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        className="nav-material"
                        activeTab={activeTab}
                      >
                        <TabPane tabId="1">
                          <div className="prod-det-desc-box">
                            {productDetail?.FullDescription !== undefined ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: setProductDescriptionImagesUrl(
                                    productDetail.FullDescription
                                  ),
                                }}
                              ></div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div className="">
                            <div className="single-product-tables">
                              <div class="product-info-custom">
                                <div class="product-info-custom-item">
                                  <span class="product-info-custom-label">
                                    {t("tags")}
                                  </span>
                                  {productDetail?.ProductTagsJson?.map(
                                    (item, idx) => (
                                      <span
                                        className="product-info-custom-value"
                                        key={idx}
                                      >
                                        {item.TagName}
                                        {idx !==
                                          productDetail.ProductTagsJson - 1 &&
                                          ",  "}
                                      </span>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="3">
                          <div className="">
                            <div className="single-product-tables">
                              <div class="product-info-custom">
                                <div class="product-info-custom-item">
                                  <span class="product-info-custom-label">
                                    {LocalizationLabelsArray.length > 0
                                      ? replaceLoclizationLabel(
                                          LocalizationLabelsArray,
                                          "Shipping Free: ",
                                          "lbl_prd_det_head_shippingfree"
                                        )
                                      : t("shipping free")}
                                    :
                                  </span>
                                  <span class="product-info-custom-value">
                                    {productDetail?.IsShippingFree == true
                                      ? t("yes")
                                      : t("no")}
                                  </span>
                                </div>
                                <div class="product-info-custom-item">
                                  <span class="product-info-custom-label">
                                    {t("delivery methods")}
                                  </span>
                                  {productDetail?.ProductShipMethodsJson?.map(
                                    (item, index) => (
                                      <span
                                        className="product-info-custom-value"
                                        key={index}
                                      >
                                        {item.ShippingMethodName}
                                        {index !==
                                          productDetail.ProductShipMethodsJson
                                            .length -
                                            1 && ", "}
                                      </span>
                                    )
                                  )}
                                </div>
                                <div class="product-info-custom-item">
                                  <span class="product-info-custom-label">
                                    {t("estimated shipping days")}
                                  </span>

                                  <span class="product-info-custom-value">
                                    {productDetail?.EstimatedShippingDays}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="4">
                          <div className="mt-3">
                            <div className="row">
                              <div className="col-sm-6">
                                <dl>
                                  <dt>
                                    {t(
                                      "here are 5 more great reasons to buy from us"
                                    )}
                                  </dt>

                                  <dd style={{ marginTop: "10px" }}>
                                    {t("- secure online transactions")}
                                  </dd>
                                  <dd>{t("- very affordable pricing")}</dd>
                                  <dd>{t("- fast and reliable shipping")}</dd>
                                  <dd>{t("- excellent customer service")}</dd>
                                  <dd>{t("- high-quality products")}</dd>
                                </dl>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="5">
                          {productReviews !== null &&
                          productReviews !== undefined &&
                          productReviews.length > 0 ? (
                            <Row>
                              <Col sm="12" lg="12">
                                <div
                                  className="title2"
                                  style={{
                                    textAlign: "left",
                                    background: "none",
                                  }}
                                >
                                  <h4
                                    style={{
                                      textTransform: "none",
                                      display: "inline-block",
                                    }}
                                  >
                                    {"customer reviews"}
                                  </h4>
                                </div>

                                <div className="mt-3">
                                  {productReviews?.map((item, idx) => (
                                    <div
                                      className="review-item-prod-detail"
                                      key={idx}
                                    >
                                      <ProductRatingStars
                                        Rating={
                                          item.Rating == undefined ||
                                          item.Rating == null
                                            ? 5
                                            : item.Rating
                                        }
                                      />

                                      <h3>{item.Title}</h3>
                                      <span>
                                        <strong>{item.ReviewerName}</strong>{" "}
                                        {"on "}
                                        <strong>{item.ReviewDate}</strong>
                                      </span>
                                      <p>
                                        {" "}
                                        {makeProductShortDescription(
                                          item.Body,
                                          500
                                        )}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <></>
                          )}

                          <Form>
                            <div className="form-row row">
                              <Col md="12" className="mt-3">
                                <div
                                  className="title2"
                                  style={{
                                    textAlign: "left",
                                    background: "none",
                                  }}
                                >
                                  <h4
                                    style={{
                                      textTransform: "none",
                                      display: "inline-block",
                                    }}
                                  >
                                    {t("write a review")}
                                  </h4>
                                </div>
                                <div className="">
                                  <Label className="mb-0">{t("rating")}</Label>
                                  <div className="star-rating-review-form">
                                    {[1, 2, 3, 4, 5].map((num) => (
                                      <span
                                        key={num}
                                        className={
                                          num == 1 || num <= ReviewRating
                                            ? "star-filled"
                                            : "star-empty"
                                        }
                                        onClick={() => handleClick(num)}
                                      >
                                        &#9733;
                                      </span>
                                    ))}
                                  </div>
                                </div>
                              </Col>
                              <Col md="6">
                                <Label htmlFor="name" id="lbl_prd_det_name">
                                  {t("name")}
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  name="name"
                                  placeholder={t("enter your name")}
                                  required
                                  value={ReviewerName}
                                  onChange={(e) =>
                                    setReviewerName(e.target.value)
                                  }
                                />
                              </Col>
                              <Col md="6">
                                <Label htmlFor="email" id="lbl_prd_det_email">
                                  {"Email"}
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  placeholder={t("enter your email")}
                                  required
                                  value={ReviewerEmail}
                                  onChange={(e) =>
                                    setReviewerEmail(e.target.value)
                                  }
                                />
                              </Col>
                              <Col md="12">
                                <Label
                                  htmlFor="review-title"
                                  id="lbl_prd_det_reviewtitle"
                                >
                                  {t("review title")}
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="review-title"
                                  name="review-title"
                                  placeholder={t("enter your review subjects")}
                                  required
                                  value={ReviewTitle}
                                  onChange={(e) =>
                                    setReviewTitle(e.target.value)
                                  }
                                />
                              </Col>
                              <Col md="12">
                                <Label
                                  htmlFor="review-body"
                                  id="lbl_prd_det_bodyreview"
                                >
                                  {t("body of review (1000)")}
                                </Label>
                                <textarea
                                  className="form-control"
                                  rows={4}
                                  placeholder={t("write your testimonial here")}
                                  name="review-body"
                                  id="review-body"
                                  required={true}
                                  value={ReviewBody}
                                  onChange={(e) =>
                                    setReviewBody(e.target.value)
                                  }
                                />
                              </Col>
                              <Col md="12">
                                <button
                                  className="btn btn-normal"
                                  type="button"
                                  onClick={() => SubmitReviewForm()}
                                >
                                  {t("submit review")}
                                </button>
                              </Col>
                            </div>
                          </Form>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </section>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      {/* <RelatedProducts ProductId={ProductId} /> */}

      <ProductVariants
        ProductId={ProductId}
        showProductVariantsPopup={showProductVariantsPopup}
        closeProductVariantPopup={closeProductVariantPopup}
        setProductVariantsFromPopup={setProductVariantsFromPopup}
        productAllAttributes={productAllAttributes}
        setProductAllAttributes={setProductAllAttributes}
      />

      {sizeGuide ? (
        <SizeGuide
          closeSizeGuide={closeSizeGuide}
          openSizeGuide={openSizeGuide}
          SizeGuide={SizeGuide}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ProductDetail;
