import React from "react";
import { Row, Col, Container, Media } from "reactstrap";
import myImage from "../../../resources/custom/images/call_img.png";
import { t } from "i18next";

const ContactBanner = () => {
  return (
    <section className="section-pt-space">
      <div className="contact-banner">
        <Container>
          <Row>
            <Col>
              <div className="contact-banner-contain">
                <div className="contact-banner-img">
                  <Media
                    src={myImage}
                    className="img-fluid"
                    alt="call-banner"
                  />
                </div>
                <div>
                  <h3 style={{ fontWeight: "400" }}>
                    {t("please contact us")}
                  </h3>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default ContactBanner;
