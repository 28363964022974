import { Helmet } from "react-helmet";
import { Col, Row } from "reactstrap";
import Config from "../../../helpers/Config";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import { useState } from "react";
import { t } from "i18next";

const contents = [
  {
    title: "bulk production content 1",
    content: ["bulk production content 2", "bulk production content 3"],
  },
  {
    title: "bulk production content 4",
    content: ["bulk production content 5"],
  },
  {
    title: "bulk production content 6",
    content: ["bulk production content 7", "bulk production content 8"],
  },
  {
    title: "bulk production content 9",
    content: ["bulk production content 10"],
  },
  {
    title: "bulk production content 11",
    list: [
      "bulk production content 12",
      "bulk production content 13",
      "bulk production content 14",
      "bulk production content 15",
      "bulk production content 16",
    ],
  },
];

const BulkProduction = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);
  return (
    <>
      <Helmet>
        <title>{siteTitle} - Bulk Production</title>
        <meta name="description" content={siteTitle + " - Bulk Production"} />
        <meta name="keywords" content="Bulk Production"></meta>
      </Helmet>

      <SiteBreadcrumb title="bulk production" parent="Home" />

      <section className="about-page  mt-2 mt-md-4">
        <div className="custom-container" style={{ maxWidth: "900px" }}>
          <Row>
            {contents.map((content, index) => (
              <Col xs="12" className="mb-2 mt-1">
                <h4 className="mb-2">{t(content.title)}</h4>
                {content.list && content.list.length > 0 ? (
                  <ul style={{ listStyleType: "disc", paddingLeft: "32px" }}>
                    {content.list.map((item, index) => (
                      <li
                        key={index}
                        className="mb-2"
                        style={{ display: "list-item" }}
                      >
                        <p>{t(item)}</p>
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
                {content.content &&
                  content.content.length > 0 &&
                  content.content.map((item, index) => (
                    <p className="mb-2" key={index}>
                      {t(item)}
                    </p>
                  ))}
              </Col>
            ))}
          </Row>
        </div>
      </section>
    </>
  );
};

export default BulkProduction;
