import { MakeApiCallAsync } from "helpers/ApiHelpers";
import { GetDefaultCurrencyCode } from "helpers/CommonHelper";
import Config from "helpers/Config";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import rootAction from "stateManagment/actions/rootAction";

const VerifyCreateOrder = () => {
  const stringify = localStorage.getItem("v1shop_createOrder");
  const createOrder = stringify ? JSON.parse(stringify) : undefined;

  const loginUserDataJson = useSelector((state) => state.userReducer.user);
  const guestUserId = useSelector((state) => state.userReducer.guestUser);
  const loginUser = JSON.parse(loginUserDataJson ?? "{}");

  const dispatch = useDispatch();

  const createPayment = async () => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const params = {
      userId: loginUser.UserID || guestUserId,
      total: createOrder.total,
      currency: GetDefaultCurrencyCode(),
      language: createOrder.language || "en",
      isTestUrl: false,
    };
    // console.log(params);

    const apiResponse = await MakeApiCallAsync(
      Config.END_POINT_NAMES["PAYMENT_CREATE"],
      "", //subUrl API
      params, //  params
      headers,
      "POST",
      true
    );

    return apiResponse;
  };

  useEffect(() => {
    if (!createOrder) {
      alert("createOrder empty");
      window.close();
      return;
    }

    const handlePayment = async () => {
      if (Number(createOrder.paymentMethodId) === 8) {
        window.location.replace("/braintree/new");
      } else {
        const res = await createPayment();
        const paymentLink = res.data.data.paymentLink;
        if (paymentLink) {
          dispatch(rootAction.commonAction.setLoading(false));
          window.location.replace(paymentLink);
        }
      }
    };
    handlePayment();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrder, loginUser]);

  return (
    <div className="my-5">
      <div className="container">
        <div className="d-flex justify-content-center mt-5">
          <Spinner className="me-2" animation="border" variant="primary" />
          <h3>Processing for create payment</h3>
        </div>
      </div>
    </div>
  );
};

export default React.memo(VerifyCreateOrder);
