import { Media } from "reactstrap";
import supperSale from "./../../../resources/themeContent/images/supper-sale.jpg";
import supperSaleMobile from "./../../../resources/themeContent/images/supper-sale-mobile.jpg";
import useMobileSize from "helpers/utils/isMobile";

const DiscountBannerOmg = () => {
  const isMobile = useMobileSize(576);
  return (
    <section
      className="custom-container"
      style={{ paddingTop: "16px", paddingBottom: "16px" }}
    >
      <Media
        src={isMobile ? supperSaleMobile : supperSale}
        className="img-fluid"
        alt="app-banner"
        style={{
          borderRadius: isMobile ? "12px" : "16px",
          minHeight: "96px",
          objectFit: "cover",
        }}
      />
    </section>
  );
};

export default DiscountBannerOmg;
