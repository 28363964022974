import { useRoutes } from "react-router-dom";
import HomeLayout from "web/components/layout/HomeLayout";
import MainLayout from "web/components/layout/MainLayout";
import { HomeRoutes, UserRoutes } from "./userRoutes";

const Router = () => {
  const routes = [
    {
      path: "/",
      element: <HomeLayout />,
      children: HomeRoutes,
    },
    {
      path: "/",
      element: <MainLayout />,
      children: UserRoutes,
    },
  ];
  return useRoutes(routes);
};

export default Router;
