import { Helmet } from "react-helmet";
import { Col, Row } from "reactstrap";
import Config from "../../../helpers/Config";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import { useState } from "react";
import { t } from "i18next";

const contents = [
  {
    title: "pre-order",
    list: [
      "pre-order content 1",

      "pre-order content 2",

      "pre-order content 3",

      "pre-order content 4",

      "pre-order content 5",
    ],
  },
  {
    title: "pre-order content 6",
    list: [
      "pre-order content 7",

      "pre-order content 8",

      "pre-order content 9",

      "pre-order content 10",

      "pre-order content 11",
    ],
  },
];

const OrderProcess = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);
  return (
    <>
      <Helmet>
        <title>{siteTitle} - Ordering Process</title>
        <meta name="description" content={siteTitle + " - Ordering Process"} />
        <meta name="keywords" content="Ordering Process"></meta>
      </Helmet>

      <SiteBreadcrumb title="ordering process" parent="Home" />

      <section className="about-page  mt-2 mt-md-4">
        <div className="custom-container" style={{ maxWidth: "900px" }}>
          <Row>
            {contents.map((content, index) => (
              <Col xs="12" className="mb-2 mt-1">
                <h4 className="mb-2">{t(content.title)}</h4>
                {content.list && content.list.length > 0 ? (
                  <ul style={{ listStyleType: "disc", paddingLeft: "32px" }}>
                    {content.list.map((item, index) => (
                      <li
                        key={index}
                        className="mb-2"
                        style={{ display: "list-item" }}
                      >
                        <p>{t(item)}</p>
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
                {content.content &&
                  content.content.length > 0 &&
                  content.content.map((item, index) => (
                    <p className="mb-2" key={index}>
                      {t(item)}
                    </p>
                  ))}
              </Col>
            ))}
          </Row>
        </div>
      </section>
    </>
  );
};

export default OrderProcess;
